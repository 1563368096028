import { Component, OnInit, Inject } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Project } from '../../../models/project';
import { ProjectsService } from '../../../services/projects/projects.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-projects-new',
  templateUrl: './projects-new.component.html',
  styleUrls: ['./projects-new.component.css']
})
export class ProjectsNewComponent implements OnInit {
  private title = new FormControl('', [Validators.required, Validators.minLength(3), Validators.maxLength(34)]);
  private description = new FormControl('', [Validators.required, Validators.minLength(3), Validators.maxLength(150)]);
  private project:Project;
  private loading:boolean;
  constructor(
    private _projectsService:ProjectsService,
    public _snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<ProjectsNewComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.project = new Project("");
    this.loading = false;
   }

  ngOnInit() {
  }

  getTitleError(){
    return this.title.hasError('required') ? 'Por favor ingresa el título' :
            this.title.hasError('minlength') ? 'Min. 5 caracteres':
              this.title.hasError('maxlength') ? 'Max. 34 caracteres':
            '';
  }

  getDescriptionError(){
    return this.description.hasError('required') ? 'Por favor ingresa una descripción' :
            this.description.hasError('minlength') ? 'Min. 30 caracteres':
              this.description.hasError('maxlength') ? 'Max. 150 caracteres':
            '';
  }

  saveProject(){
    if(this.validForm()){
      this.loading = true;
      this.project.name = this.title.value;
      this.project.json.description = this.description.value;
      this.project.json.has_cover_page = false;
      this._projectsService.createProject(this.project).subscribe(
        success=>{
          let project:any = success;
          
          this.addStandarFile(project.url, "no_delete/audio.mp3", "audio.mp3", "Audio MVS");
          this.addStandarFile(project.url, "no_delete/defensa.png", "defensa.png", "defensa");
          this.addStandarFile(project.url, "no_delete/delantero.png", "delantero.png", "delantero");
          this.addStandarFile(project.url, "no_delete/volante.png", "volante.png", "volante");
          this.addStandarFile(project.url, "no_delete/arquero.png", "arquero.png", "arquero");
          //this.addStandarFile(project.url, "no_delete/audio_1.mp3", "audio_1.mp3", "audio 1");
          this.addStandarFile(project.url, "no_delete/water_mark.png", "water_mark.png", "water mark");
          this.addStandarFile(project.url, "no_delete/mira.mov", "mira.mov", "mira");
          
          setTimeout(() => {
            this.title.reset()
            this.description.reset();
            this.loading = false;
            Swal.fire({
              type: "success",
              text: "El proyecto fue creado"
            });
            this.dialogRef.close();
          }, 2000);
          
        },
        error=>{
          this.loading = false;
         
          Swal.fire({
            type: "error",
            text: "Error al crear proyecto"
          });
        }
      );
    }
  }

  addStandarFile(url_project, file, file_name, title){
    this._projectsService.addStandarFile(url_project, file, file_name, title).subscribe(
        success=>{
          console.log("Add file");
          this.loading = false;
      },
      error=>{
          this.loading = false;
          Swal.fire({
            type: "error",
            text: "Error al agregar marker"
          });
        }
    );
  }

  validForm(){
    let valid=true;
    if(this.title.invalid || this.description.invalid){
      valid = false;
    }
    return valid;
  }

}
