// clase para manejar los clips
export class Clic{
    public id:any;
    public file:any;
    public position:number;
    public start:number;
    public end:number;
    public layer:number;
    public project: any;
    public json: any;
    public date_created:string;
    public date_updated:string;
    public url:string;
    public pixels_lenght:number;
    public thumnails_short:any[];
    public thumnails_medium:any[];
    public thumnails_large:any[];
    public thumnails_position:any[];
    public is_last:boolean;
    constructor(){}
}

// clase para manejar los files de cada proyecto
export class File{
    public url:string;
    public id:number;
    public media:string;
    public project:any;
    public actions:string[];
    public json:any;
    constructor(){}
}

// clase para manejar los efectos de los clips
export class Effect{
    public id:number;
    public title:string;
    public type: string;
    public position: number;
    public start: number;
    public end: number;
    public layer: number;
    public project:string;
    public json:any;
    constructor(){
    }
}

// clase para manejar los eventos
export class Event{
    public id:string;
    public minute:string;
    public second:string;
    public factor_to_order:string;
    public description:string;
    public clip_id:number;
    constructor(){}
}

// clase para manejar los titulos de las portadas
export class Tittle{
    private font_size:number;
    private font_name:string;
    private fill_color: string;
    private template:string;
    private fill_opacity:number;
    private stroke_color:string;
    private stroke_size:number;
    private stroke_opacity:number;
    private drop_shadow:boolean;
    private background_color:string;
    private background_opacity:number;

    constructor(public text:string, public text1:string, public text2:string, public text3:string, public text4:string){
         this.font_size=80.0;
         this.font_name="Noto Sans";
         this.fill_color= "#4a4a44";
         this.template="Custom-Text.svg";
         this.fill_opacity=1.0;
         this.stroke_color="#000000";
         this.stroke_size=0.0;
         this.stroke_opacity=0.0;
         this.drop_shadow=true;
         this.background_color="#000000";
         this.background_opacity=0.0;
    }
}

// clase para manejar las fuentes de video que se pueden asociar a un video
export class VideoSource{
    public title:string;
    public url:string;
    public is_active:boolean;
    constructor(){
    }
}

// clase para manejar las fuentes de video que se pueden asociar a un video
export class Exports{
    public id:number;
    public video_format:string;
    public video_codec:string;
    public video_bitrate:number;
    public audio_codec:string;
    public audio_bitrate:number;
    public start_frame:number;
    public end_frame:number;
    public json:any;
    public export_type:string;
    public status:string;
    public webhook:string;
    public progress:any;
    constructor(public project:string, name_file:string){
        this.export_type = "video";
        this.video_format = "mp4";
        this.video_codec = "libx264";
        this.video_bitrate = 1000000;
        this.webhook = "";
        this.audio_codec = "libfdk_aac";
        this.audio_bitrate = 192000;
        this.start_frame = 1;
        this.end_frame = 0;
        this.json =  { "url": "export/"+name_file+".mp4", "bucket": "groupmvs", "acl": "public-read"}
        this.status = "pending";
    }
}
