import { Component, OnInit, Inject } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Project } from '../../../models/project';
import { ProjectsService } from '../../../services/projects/projects.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-project-edit',
  templateUrl: './project-edit.component.html',
  styleUrls: ['./project-edit.component.css']
})

export class ProjectEditComponent implements OnInit {
  private title = new FormControl('', [Validators.required, Validators.minLength(3), Validators.maxLength(34)]);
  private description = new FormControl('', [Validators.required, Validators.minLength(3), Validators.maxLength(150)]);
  private project:Project;
  private loading:boolean;
  constructor(
    private _projectsService:ProjectsService,
    public _snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<ProjectEditComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { 
    this.project = new Project("");
    this.loading = false;
  }

  ngOnInit() {
    this.loadProject();
  }

  getTitleError(){
    return this.title.hasError('required') ? 'Por favor ingresa el título' :
            this.title.hasError('minlength') ? 'Min. 5 caracteres':
              this.title.hasError('maxlength') ? 'Max. 34 caracteres':
            '';
  }

  getDescriptionError(){
    return this.description.hasError('required') ? 'Por favor ingresa una descripción' :
            this.description.hasError('minlength') ? 'Min. 30 caracteres':
              this.description.hasError('maxlength') ? 'Max. 150 caracteres':
            '';
  }

  loadProject(){
    this._projectsService.getProject(this.data.project_id).subscribe(
      success=>{
        let success_body:any = success; 
        this.project = success_body;
        this.title.setValue(this.project.name);
        this.description.setValue(this.project.json.description);
      },
      error=>{
        //this._snackBar.open("Error al cargar el proyecto", "Cerrar", {
        //  duration: 5000,
        //});
        Swal.fire({
          type: "error",
          text: "Error al cargar el proyecto"
        });
      }
    );
  }

  updateProject(){
    if(this.validForm()){
      this.project.name = this.title.value;
      this.project.json.description = this.description.value;
      this._projectsService.updateProject(this.project, this.project.id).subscribe(
        success=>{
          let success_body:any = success; 
          this.project = success_body;
          console.log("Clic updated");
          Swal.fire({
            type: "success",
            text: "El proyecto ha sido actualizado"
          }); 
          this.dialogRef.close({id_project:this.data.project_id});
        },
        error=>{
          //this._snackBar.open("Error al actualizar el clic.", "Cerrar", {
          //  duration: 5000,
          //});
          Swal.fire({
            type: "error",
            text: "Error al actualizar el clic."
          }); 
        }
      );
    }
    
  }

  validForm(){
    let valid=true;
    if(this.title.invalid || this.description.invalid){
      valid = false;
    }
    return valid;
  }


}
