import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Project, Registered } from "../../models/project";
import { Clic, Effect, Tittle, Exports} from "../../models/editor";

@Injectable({
  providedIn: 'root'
})
export class ProjectsService {
  constructor(
    public _http:HttpClient
    ) { }

  // almacena project en local storage
  setProjectOnStorage(project:Project){
    this.deleteProjectOnStorage();
    let storageWell:boolean = true;
    if (typeof(Storage) !== "undefined") {
        if(project!=null){
          localStorage.setItem("project", JSON.stringify(project));
        }else{
          localStorage.setItem("project", "none");
          storageWell=false;
        }
    }else {
        alert("Local Storage doesn't support, Update your navigator");
        storageWell=false;
    }
    return storageWell;
  }

  // recuperar project de local storage
  getProjectOnStorage():Project{
    let project:Project;
    if(typeof(Storage)!="undefined"){
      project=JSON.parse(localStorage.getItem("project"));
    }else {
      project=null;
        alert("Local Storage doesn't support, Update your navigator");
    }
    return project;
  }

  // almacena regostrado en local storage
  setRegisteredOnStorage(registered:Registered){
    this.deleteProjectOnStorage();
    let storageWell:boolean = true;
    if (typeof(Storage) !== "undefined") {
        if(registered!=null){
          localStorage.setItem("registered", JSON.stringify(registered));
        }else{
          localStorage.setItem("registered", "none");
          storageWell=false;
        }
    }else {
        alert("Local Storage doesn't support, Update your navigator");
        storageWell=false;
    }
    return storageWell;
  }

  // recuperar project de local storage
  getRegisteredOnStorage():Registered{
    let registered:Registered;
    if(typeof(Storage)!="undefined"){
      registered=JSON.parse(localStorage.getItem("registered"));
    }else {
      registered=null;
        alert("Local Storage doesn't support, Update your navigator");
    }
    return registered;
  }

  // almacena project en local storage
  setFlagLogInOnStorage(project:Project){
    this.deleteProjectOnStorage();
    let storageWell:boolean = true;
    if (typeof(Storage) !== "undefined") {
        if(project!=null){
          localStorage.setItem("login", JSON.stringify(project));
        }else{
          localStorage.setItem("project", "none");
          storageWell=false;
        }
    }else {
        alert("Local Storage doesn't support, Update your navigator");
        storageWell=false;
    }
    return storageWell;
  }

  // recuperar project de local storage
  getFlagLogInOnStorage():Project{
    let project:Project;
    if(typeof(Storage)!="undefined"){
      project=JSON.parse(localStorage.getItem("login"));
    }else {
      project=null;
        alert("Local Storage doesn't support, Update your navigator");
    }
    return project;
  }
  
  

  // eliminar project de local storage
  deleteProjectOnStorage(){
    if(typeof(Storage)!="undefined"){
      localStorage.removeItem("project");
    }
  }

  createProject(project:Project){
    return this._http.post("projects/",project,{});
  }
  updateProject(project:Project, project_id:number){
    return this._http.put("projects/"+project_id+"/",project,{});
  }
  getProjects(){
    return this._http.get('projects/',{observe: "response"});
  }
  getProject(project_id){
    return this._http.get("projects/"+project_id+"/",{});
  }
  deleteProject(file_id){
    return this._http.delete("projects/"+file_id+"/",{});
  }
  createClip(clip:Clic){
    return this._http.post("clips/",clip,{});
  }
  deleteClip(clip_id){
    return this._http.delete("clips/"+clip_id+"/",{});
  }
  getFile(file_id){
    return this._http.get('files/'+file_id+'/',{observe: "response"});
  }
  getFiles(project_id:number){
    return this._http.get('projects/'+project_id+'/files/',{observe: "response"});
  }
  deleteFile(file_id){
    return this._http.delete("files/"+file_id+"/",{});
  }
  getClic(url:string){
    return this._http.get(url ,{observe: "response"});
  }
  deleteClicById(clip_id){
    return this._http.delete("clips/"+clip_id+"/"  ,{observe: "response"});
  }
  getClicById(cli_id){
    return this._http.get("clips/"+cli_id+"/" ,{observe: "response"});
  }
  getClips(project_id:number){
    return this._http.get( 'projects/'+project_id+'/clips/',{observe: "response"});
  }
  updateClic(clip:Clic, clic_id:number){
    delete clip.file;
    return this._http.patch("clips/"+clic_id+"/",clip,{});
  }
  getThumnailsClip(file_id:number, thumanil_position:number){
    //let headers_post = new HttpHeaders().set('Content-Type','multipart/form-data; boundary=----WebKitFormBoundaryzHtkkJDA63Q4whgz');
    let thumnail = new FormData();
    thumnail.append('frame_number', String(thumanil_position));
    thumnail.append('width', "288");
    thumnail.append('height', "162");
    thumnail.append('image_quality', "70");
    thumnail.append('image_format', "JPG");
    return this._http.post("files/"+file_id+"/thumbnail/",thumnail, {responseType: 'blob'});
  }
  getThumnailClipToMarker(file_id:number, thumanil_position:number){
    //let headers_post = new HttpHeaders().set('Content-Type','multipart/form-data; boundary=----WebKitFormBoundaryzHtkkJDA63Q4whgz');
    let thumnail = new FormData();
    thumnail.append('frame_number', String(thumanil_position));
    thumnail.append('width', "576");
    thumnail.append('height', "324");
    thumnail.append('image_quality', "100");
    thumnail.append('image_format', "JPG");
    return this._http.post("files/"+file_id+"/thumbnail/",thumnail, {responseType: 'blob'});
  }
  addStandarFile(url_project:string, file:string, file_name:string, title:string){
    let json =  { "url": file, "bucket": "groupmvs", "name": file_name, "title":title};
    let file_body = new FormData();
    file_body.append('project', url_project);
    file_body.append('media', "");
    file_body.append('json',JSON.stringify(json));
    return this._http.post("files/",file_body,{});
  }
  getEffect(effect_id:number){
    return this._http.get("effects/"+effect_id+"/",{});
  }
  createEffect(effect:Effect){
    return this._http.post("effects/",effect,{});
  }

  updateEffect(effect:Effect, effect_id){
    return this._http.patch("effects/"+effect_id+"/",effect,{});
  }
  deleteEffect(effect_id){
    return this._http.delete("effects/"+effect_id+"/",{});
  }
  updaloadImge(form_data){
    return this._http.post("files/", form_data);
  }
  createTittle(project_id, tittle:Tittle){
    return this._http.post("projects/"+project_id+"/title/",tittle,{});
  }
  getInfoRegister(token:string){
    return this._http.get("registrados/"+token+"/",{});
  }
  exportVideo(export_body:Exports, project_id:number){
    return this._http.post("projects/"+project_id+"/exports/",export_body,{});
  }
  getExports(project_id){
    return this._http.get("projects/"+project_id+"/exports/",{});
  }
}
