import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ProjectsService } from '../../../services/projects/projects.service';
import { Project} from '../../../models/project';

@Component({
  selector: 'app-project-delete',
  templateUrl: './project-delete.component.html',
  styleUrls: ['./project-delete.component.css']
})
export class ProjectDeleteComponent implements OnInit {
  private project_id:number;
  public loader:boolean;
  constructor(
    public _snackBar: MatSnackBar,
    private _projectsService:ProjectsService,
    public dialogRef: MatDialogRef<ProjectDeleteComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { 
    this.project_id = 0;
  }

  // inciar el componente
  ngOnInit() {
    this.project_id = this.data.project_id;
  }

  // cancelar el eliminado y cerrar componente
  cancelDelete(){
    this.dialogRef.close();
  }

  // ejecutar el eliminado
  delete(){
    this.deleteProject();
  }

  // borrar el clip principal
  deleteProject(){
    this.loader = true;
    this._projectsService.deleteProject(this.project_id).subscribe(
      success=>{
          this._snackBar.open("Project eliminado", "Cerrar", {
            duration: 5000,
          });
          this.loader=false;
          this.dialogRef.close({id_project:this.project_id});
      },
      error=>{
        console.log(error);
        this.loader=false;
      }
    );
  }
}
