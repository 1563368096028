import { Component, OnInit, Inject } from '@angular/core';
import { ProjectsService } from '../../../services/projects/projects.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Clic, File } from '../../../models/editor';
import {AuthService} from '../../../services/auth/auth.service';

@Component({
  selector: 'app-adjusment-audio-clip',
  templateUrl: './adjusment-audio-clip.component.html',
  styleUrls: ['./adjusment-audio-clip.component.css']
})
export class AdjusmentAudioClipComponent implements OnInit {
  private clip:Clic;
  private msbapTitle = 'Audio Title';
  private msbapAudioUrl:string;
  private msbapDisplayTitle = false;
  private msbapDisplayVolumeControls = false;
  private showPlayer: boolean = false; 
  constructor(
    private _projectsService:ProjectsService,
    public dialogRef: MatDialogRef<AdjusmentAudioClipComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _authService:AuthService,
  ) { 
    this.clip = new Clic();
  }

  ngOnInit() {
    this.getDataClic();
  }

  getDataClic(){
    let route = "clips/"+this.data.audio_clip_id+"/";
    this._projectsService.getClic(route).subscribe(
      success=>{
        let clip:any = success;
        clip = clip.body;
        this.clip = clip;
        let token_auth=this._authService.getStoragedToken();
        let media_signed = this.clip.json.media_file.replace("media","signed");
        this.msbapAudioUrl = media_signed+"?Authorization="+token_auth;
        this.msbapAudioUrl.replace("media","signed");
        this.showPlayer = true;
      },
      error=>{
        console.log(error);
      }
    );
  }
}
