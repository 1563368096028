import { Component, OnInit } from '@angular/core';
import { Router, Params, ActivatedRoute } from '@angular/router';

import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ProjectsService } from '../../services/projects/projects.service';
import { AuthService } from '../../services/auth/auth.service';
import { Project, Registered } from '../../models/project';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-load-token',
  templateUrl: './load-token.component.html',
  styleUrls: ['./load-token.component.css']
})
export class LoadTokenComponent implements OnInit {
  private token:string;
  private registered:Registered;
  constructor(
    private _route:ActivatedRoute,
    private _router:Router,
    public dialog: MatDialog,
    public _projectsService:ProjectsService,
    private _authService:AuthService
  ) { 
    this.registered = new Registered();
  }

  ngOnInit() {
    this.getToken();
  }

  // extrae token de parametro de URL
  getToken(){
    this._route.params.forEach((params:Params)=>{
      this.token=params["token"];
      this.processToken();
    });
  }

  // procesa el token
  processToken(){
    console.log(this.token);
    if(this.token != undefined){
      this.getInfo();
      //this.getProjects();
    }
  }
  // trae la información del usuario registrado
  getInfo(){
    this._projectsService.getInfoRegister(this.token).subscribe(
      success=>{
        let success_body:any=success;
        this.registered = success_body;
        this._authService.setStoragedToken(this.registered.token);
        this._projectsService.setRegisteredOnStorage(this.registered);
        this.toProjects();
      },
      error=>{
        Swal.fire({
          type: 'error',
          text : "Error al cargar los proyectos"
        });
      }
    );
  }

  toProjects(){
    this._router.navigate(['projects']);
  }

  

}
