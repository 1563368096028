import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Clic, Effect } from '../../../models/editor';
import { ProjectsService } from '../../../services/projects/projects.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-adjust-transition',
  templateUrl: './adjust-transition.component.html',
  styleUrls: ['./adjust-transition.component.css']
})
export class AdjustTransitionComponent implements OnInit {
  private effect:Effect;
  private to_response_to_parent:any;
  private transition:any;
  private transition_options:any[];
  private clip_id:number;
  constructor(
    private _snackBar:MatSnackBar,
    private _projectsService:ProjectsService,
    public dialogRef: MatDialogRef<AdjustTransitionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { 
    this.effect = new Effect();
    this.to_response_to_parent={
      transition_start:"",
      transition_body:"",
      transition_changed:false,
      clip_id:""
    };
    this.transition_options = [
      {
        id:"fade",
        title:"Fade"
      },
      {
        id:"circle_in_to_out",
        title:"Circle in out"
      }
    ];
  }

  ngOnInit() {
    this.getDataEffects();
  }

  getDataEffects(){
    console.log(this.data.effects);
    this._projectsService.getEffect(this.data.effects.id).subscribe(
      success=>{
        let effect:any = success;
        this.effect = effect;
        let path = this.effect.json.reader.path;
        let animation_type = path.split("/")[8];
        let animation = animation_type.split(".")[0];
        this.to_response_to_parent.transition_start = animation;
        if( "fade" == animation ){
          this.transition="fade";
        }else if("circle_in_to_out" == animation){
          this.transition="circle_in_to_out";
        }
        this.clip_id = this.data.clip_id;
      },
      error=>{
        Swal.fire({
          type: "error",
          text: "Error al cargar el efecto."
        });
      }
    );
  }

  validateChanges(){
    let changes = false;
    if(this.to_response_to_parent.transition_start==this.transition){
      this.to_response_to_parent.transition_changed = false;
    }else{
      this.to_response_to_parent.transition_changed = true;
      changes = true;
    }
    return changes;
  }

  updateEffect(){
    let path = this.makeNewPath();
    this.effect.json.reader.path = path;
    this._projectsService.updateEffect(this.effect, this.effect.id).subscribe(
      success=>{
        Swal.fire({
          type: "success",
          text: "La transición ha sido cambiada correctamente."
        });
        this.closeComponent();
      },
      error=>{
        Swal.fire({
          type: "error",
          text: "Error al actualizar las transición.."
        });
      }
    );
  }

  makeNewPath(){
    let effect_path = "";
    let effect = "";
    if(this.transition=="fade"){
      effect_path = "/home/ubuntu/api/api_app/static/transitions/common/fade.svg";
      effect = "fade";
    }else if(this.transition=="circle_in_to_out"){
      effect_path = "/home/ubuntu/api/api_app/static/transitions/common/circle_in_to_out.svg";
      effect = "circle_in_to_out";
    }
    this.to_response_to_parent.transition_body = effect;
    return effect_path;
  }

  closeComponent(){
    this.to_response_to_parent.clip_id = this.clip_id;
    this.dialogRef.close({data:this.to_response_to_parent});
  }

  apllyChanges(){
    let validate_changes = this.validateChanges();
    if(validate_changes){
      this.updateEffect();
    }else{
      this.closeComponent();
    }
  }

}
