import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ProjectsService } from '../../../services/projects/projects.service';
import { DropzoneModule ,DropzoneComponent , DropzoneDirective, DropzoneConfigInterface } from 'ngx-dropzone-wrapper';
import {AuthService} from '../../../services/auth/auth.service';
import { Clic, File, Effect, Event, Tittle } from '../../../models/editor';
import { Project, Registered} from '../../../models/project';
import { FormControl, Validators } from '@angular/forms';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-cover-page',
  templateUrl: './cover-page.component.html',
  styleUrls: ['./cover-page.component.css']
})
export class CoverPageComponent implements OnInit {
  uploadForm: FormGroup; 
  private project:Project;
  private token:string;
  //@ViewChild(DropzoneComponent) drpzone: DropzoneComponent;
  public config:DropzoneConfigInterface;
  public clip:Clic;
  public clip_cover_page:Clic;
  public clip_cover_page_text:Clic;
  public clips:Clic[];
  private description = new FormControl('', [Validators.required, Validators.minLength(5), Validators.maxLength(200)]);
  private image_is_loaded:boolean;
  private text_is_loaded:boolean;
  private status_cover_page:number;// 1 para nuevo, 2 para editado, 3 para nada
  private have_cover_page:boolean;
  private media_image:string;
  private media_image_signed:string;
  private file_image:string;
  private text_title:string;
  private current_action:number;
  private cover_pages:any[];
  private selected_cover:any;
  private registered:Registered;
  private cover_page_clips_id:any;
  private making_action:boolean;
  private token_auth:string;
  private loader:boolean;
  constructor(
    private _authService:AuthService,
    private _snackBar:MatSnackBar,
    private _projectsService:ProjectsService,
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<CoverPageComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.clip = new Clic();
    this.clip_cover_page = new Clic();
    this.clip_cover_page_text = new Clic();
    this.image_is_loaded=false;
    this.text_is_loaded=false;
    this.clips=[];
    this.status_cover_page=1;
    this.have_cover_page= false;
    this.cover_pages=[];
    this.selected_cover="";
    this.registered = new Registered();
    this.cover_page_clips_id = {
      cover_image_clip_id:0,
      cover_tittle_clip_id:0,
      cover_tittle_file_id:0
    }
    this.making_action = false;
    this.loader=false;
   }

  ngOnInit() {
    //this.configureDropzone();
    this.getToken();
    this.getRegistered();
    this.getProject();
    this.getFiles();
  }

  getRegistered(){
    this.registered = this._projectsService.getRegisteredOnStorage();
  }

  getProject(){
    this.project = this._projectsService.getProjectOnStorage();
  }

  getToken(){
    this.token_auth=this._authService.getStoragedToken();
  }

  /*
  getDescriptionError(){
    return this.description.hasError('required') ? 'Por favor ingresa una descripción' :
            this.description.hasError('minlength') ? 'Min. 10 caracteres':
              this.description.hasError('maxlength') ? 'Max. 500 caracteres':
            '';
  }
  */

  /*

  validForm(){
    let valid=true;
    if(this.description.invalid ){
      valid = false;
    }
    return valid;
  }*/


/*
  configureDropzone(){
    this.token=this._authService.getStoragedToken();
    this.project = this._projectsService.getProjectOnStorage();
    this.config = {
      url: GLOBAL.apiMVSVideo+'files/',
      cancelReset: null,
      clickable: true,
      maxFiles: 1,
      autoReset: null,
      errorReset: null,
      paramName:"media",
      addRemoveLinks: true,
      autoProcessQueue:false,
      params: {
        "json": "{}",
        "project":this.project.url
      },
      headers: {
        'Cache-Control': null,
        'X-Requested-With': null,
        'Authorization': 'Token ' + this.token
      }
    }
  }
  */

  /*
  onFileSelect(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.uploadForm.get('media').setValue(file);
    }
  }
  */

  /*
  onSubmit() {
    const formData = new FormData();
    formData.append('media', this.uploadForm.get('media').value);
    let meia = this.uploadForm.get('media').value;
    formData.append('project', this.project.url);
    formData.append('json', "{}");
    this._projectsService.updaloadImge(formData).subscribe(
      success=>{
        console.log(success);
      },
      error=>{
        console.log(error);
      }
    );
    /*
    this.httpClient.post<any>(this.SERVER_URL, formData).subscribe(
      (res) => console.log(res),
      (err) => console.log(err)
    );
    
  }
  /*

  /*
  onUploadSuccess(event){
    let file:any = event[1];
    this.createdCoverPageImageClip(file);
    console.log(event);
  }
  */

 changeCover(event){
  this.media_image = event.value;
  let token = this._authService.getStoragedToken();
  let signed_file = event.value.replace("media","signed");
  this.media_image_signed  = signed_file+"?Authorization="+token;
  this.have_cover_page=true;
  for(let i=0; i<this.cover_pages.length;i++){
    if(event.value == this.cover_pages[i].media){
      this.file_image = this.cover_pages[i].url;
    }
  }
 }

  createdCoverPageImageClip(){
    this.clip.file = this.file_image;
    this.clip.json={
      cover_page:true,
      media:this.media_image,
      file:this.file_image
    };
    this.clip.project = this.project.url;
    this.clip.position = 0;
    this.clip.start = 0;
    this.clip.end = 5;
    this.clip.layer = 1;
    this._projectsService.createClip(this.clip).subscribe(
      clip=>{
        let clip_body:any = clip;
        
        //setTimeout(() => {
          Swal.fire({
            type: "success",
            text: "La portada fue actualizada."
          });
          this.dialogRef.close({created:true, clip_id:clip_body.id, status:this.status_cover_page});
        //}, 1000);
        this.loader=false;
        
        this.structureEffectTransition(clip);
      },
      error=>{
        Swal.fire({
          type: "success",
          text: "Error al crear portada."
        });
        this.loader=false;
      });
    }

    

    /*
  onUploadError(event){
    /*
    this._snackBar.open("Error al subir la imagen de portada", "Cerrar", {
      duration: 5000,
    });
    console.log("onUploadError");
  }
  */

  /*
  onSendingmultiple(event){
    console.log(event);
  }
  */


/*
  addedFile(event){
    console.log(event);
    this.image_is_loaded=true;
    this.have_cover_page = false;
  }
  */

  /*

  removedFile(event){
    console.log(event);
    this.image_is_loaded=false;
  }
  */

  createCover(){
    if(this.validateForm()){
      this.loader=true;
      this.making_action=true;
      this.createTittle();
      //this.drpzone.directiveRef.dropzone().processQueue();
    }
  }

  
  validateForm(){
    let validate = true;
    if(this.media_image==undefined){
      validate = false;
      this._snackBar.open("Debes seleccionar una portada.", "Cerrar", {
        duration: 5000,
      });
    }
    return validate;
  }

  createdCoverPageTittleClip(file){
    this.clip.file = file.url;
    this.clip.project = this.project.url;
    this.clip.position = 0;
    this.clip.start = 0;
    this.clip.end = 5;
    this.clip.layer = 2;
    this.clip.json={
      cover_page_tittle:true,
      file_id:file.id
    };
    this._projectsService.createClip(this.clip).subscribe(
      clip=>{
        this.structureEffectTransition(clip);
        this.createdCoverPageImageClip();
      },
      error=>{
        Swal.fire({
          type: "error",
          text: "Error al crear la portada."
        });
      });
    }

    structureEffectTransition(clip){
      let fps = 30;
      let start_time_effect = 0;
      let middle_time_effect = 1;
      let end_time_effect = 2;
      let segment = clip.end - clip.start;
      let position_effect = clip.position + segment - 1;
      let project_name= this.project.url;
  
      let X1 = start_time_effect * fps + 1;
      let X2 = middle_time_effect * fps + 1;
      let X3 = end_time_effect * fps + 1;
  
      let Y1 = -1
      let Y2 = 1
      let Y3 = -1
  
      this.createEffectsTransition(position_effect, start_time_effect, end_time_effect,project_name, X1, X2, X3, Y1, Y2,Y3, clip); 
    }
    
    // crear transicion de efecto
    createEffectsTransition(position_parameter, start_parameter, end_param, project_param ,X1, X2, X3, Y1, Y2, Y3, clip){
      let transition = new Effect();
      transition.title = "Transition";
      transition.type = "Mask";
      transition.start = start_parameter;
      transition.end = end_param;
      transition.position = position_parameter;
      transition.layer = 1;
      transition.project = project_param;
      transition.json = {
        "brightness": {
          "Points": [
              {
                  "co": {
                      "X": X1,
                      "Y": Y1
                  },
                  "handle_left": {
                      "X": 0.5,
                      "Y": 1
                  },
                  "handle_right": {
                      "X": 0.5,
                      "Y": 0
                  },
                  "handle_type": 0,
                  "interpolation": 0
              },
              {
                  "co": {
                      "X": X2,
                      "Y": Y2
                  },
                  "handle_left": {
                      "X": 0.5,
                      "Y": 1
                  },
                  "handle_right": {
                      "X": 0.5,
                      "Y": 0
                  },
                  "handle_type": 0,
                  "interpolation": 0
              },
              {
                  "co": {
                      "X": X3,
                      "Y": Y3
                  },
                  "handle_left": {
                      "X": 0.5,
                      "Y": 1
                  },
                  "handle_right": {
                      "X": 0.5,
                      "Y": 0
                  },
                  "handle_type": 0,
                  "interpolation": 0
              }
          ]
      },
        "reader": {
            "acodec": "",
            "audio_bit_rate": 0,
            "audio_stream_index": -1,
            "audio_timebase": {
                "den": 1,
                "num": 1
            },
            "channel_layout": 4,
            "channels": 0,
            "display_ratio": {
                "den": 4,
                "num": 5
            },
            "duration": 86400.0,
            "file_size": "1658880",
            "fps": {
                "den": 1,
                "num": 30
            },
            "has_audio": false,
            "has_single_image": true,
            "has_video": true,
            "height": 576,
            "interlaced_frame": false,
            "metadata": {},
            "path": "/home/ubuntu/api/api_app/static/transitions/common/fade.svg",
            "pixel_format": -1,
            "pixel_ratio": {
                "den": 1,
                "num": 1
            },
            "sample_rate": 0,
            "top_field_first": true,
            "type": "QtImageReader",
            "vcodec": "",
            "video_bit_rate": 0,
            "video_length": "2592000",
            "video_stream_index": -1,
            "video_timebase": {
                "den": 30,
                "num": 1
            },
            "width": 720
        }
      }
      this.createTransition(transition, clip);
    }

    
    createTransition(transition, clip){
      this._projectsService.createEffect(transition).subscribe(
        success=>{
          let effect:any=success;
          let type = effect.json.reader.path;
          type = type.split("/")[8];
          type = type.split(".")[0];
          let effect_body = {
            type:type,
            id:effect.id
          }
          clip.json.effect_transition = effect_body;
          //this.addClicToInterface(clip);
          this.updateClip(clip);
          //this.sortTransitions();
          
          //this.drpzone.directiveRef.dropzone().processQueue();
          
        },
        error=>{
          this._snackBar.open("Error al crear el efecto.", "Cerrar", {
            duration: 5000,
          });
        }
      );
    }
    

    createTittle(){
      let name = "Nombre: " + this.registered.nombre + " " + this.registered.apellido;
      let position = "Posición: " + this.registered.posicion;
      let large = "Estatura: "+String(this.registered.estatura);
      let size = "Peso: "+String(this.registered.peso);
      let cumple = "Nació el: "+String(this.registered.cumple);
      let tittle:Tittle = new Tittle(name, position, large, size, cumple);
      
      this._projectsService.createTittle(this.project.id, tittle).subscribe(
        success=>{
          let success_body:any = success; 
          let file:File = new File();
          file = success_body;
          this.createdCoverPageTittleClip(file);
        },
        error=>{
          this._snackBar.open("Error al crear el título", "Cerrar", {
            duration: 5000,
          });
        }
      );
    }

    getClips(){
      this._projectsService.getClips(this.project.id).subscribe(
        success=>{
          let success_body:any = success; 
          this.clips = success_body.body.results;
          this.validateCoverPage();
        },
        error=>{
          this._snackBar.open("Error al cargar las fuentes", "Cerrar", {
            duration: 5000,
          });
        }
      );
    }

    validateCoverPage(){
      this.have_cover_page=false;
      let is_fine_cover_image=false;
      let is_fine_cover_text=false;
      let clip_image:Clic = new Clic();
      let clip_text:Clic = new Clic();
      for(let i=0; i<this.clips.length; i++){
        let json = this.clips[i].json;
        let cover_page = this.clips[i].json.cover_page;
        let cover_page_text_title = this.clips[i].json.cover_page_tittle;
        if(cover_page!=undefined && cover_page==true){
          clip_image = this.clips[i];
          is_fine_cover_image = true;
          this.status_cover_page=2;
          this.have_cover_page=true;
          this.cover_page_clips_id.cover_image_clip_id = this.clips[i].id;
          this.selected_cover = json.media;
          let token = this._authService.getStoragedToken();
          let signed_file = json.media.replace("media","signed");
          this.media_image_signed = signed_file+"?Authorization="+token;
          this.media_image = json.media;
        }else if(cover_page_text_title!=undefined && cover_page_text_title!=""){
          clip_text = this.clips[i];
          is_fine_cover_text = true;
          this.status_cover_page=2;
          this.cover_page_clips_id.cover_tittle_clip_id = this.clips[i].id;
          this.cover_page_clips_id.cover_tittle_file_id = this.clips[i].json.file_id;
          this.have_cover_page=true;
        }
      }
      if(is_fine_cover_image==true && is_fine_cover_text==true){
        console.log("have cover complete");
      }else{
        if(is_fine_cover_image==false && clip_image.id!=undefined){
          this.deleteCoverPageImage(clip_image.id);
        }
        if( is_fine_cover_text==false && clip_text.id!=undefined){
          this.deleteCoverPageTitle(clip_text.id);
        }
      }
    }

    /*

    validateCoverPage(){
      this.have_cover_page=false;
      for(let i=0; i<this.clips.length; i++){
        let json = this.clips[i].json;
        let cover_page = this.clips[i].json.cover_page;
        let cover_page_text_title = this.clips[i].json.text_title;
        if(cover_page!=undefined && cover_page==true){
          this.image_is_loaded=false;
          this.status_cover_page=1;
          this.clip_cover_page = this.clips[i];
          this.media_image = this.clip_cover_page.json.media;
          this.have_cover_page=true;
        }else if(cover_page_text_title!=undefined && cover_page_text_title!=""){
          this.text_is_loaded=false;
          this.status_cover_page=2;
          this.clip_cover_page_text = this.clips[i];
          this.text_title = this.clip_cover_page_text.json.text_title;
          this.description.setValue(this.text_title);
        }

      }
    }
    */

  // actauliza el clip en la API
  updateClip(clip){
    this._projectsService.updateClic(clip, clip.id).subscribe(
      success=>{
        console.log("Clic updated");
        this.making_action=false;
      },
      error=>{
        this._snackBar.open("Error al actualizar el clic.", "Cerrar", {
          duration: 5000,
        });
      }
    );
  }
  updateCover(){
    if(this.validateForm()){
      this.loader=true;
      this.deleteCoverPageImage(this.cover_page_clips_id.cover_image_clip_id);
    }
  }
  
  deleteFile(file_id){
    this._projectsService.deleteFile(file_id).subscribe(
      success=>{
        console.log("file deleted");
      },
      error=>{
        this._snackBar.open("Error al actualizar el file.", "Cerrar", {
          duration: 5000,
        });
      }
    );
  }
  deleteCoverPageImage(clip_id){
    this._projectsService.deleteClip(clip_id).subscribe(
      success=>{
        console.log("Clic updated");
        this.deleteCoverPageTitle(this.cover_page_clips_id.cover_tittle_clip_id);
        this.deleteFile(this.cover_page_clips_id.cover_tittle_file_id);
      },
      error=>{
        this._snackBar.open("Error al eliminar la imagen de portada.", "Cerrar", {
          duration: 5000,
        });
      }
    );
  }
  deleteCoverPageTitle(clip_id){
    this._projectsService.deleteClip(clip_id).subscribe(
      success=>{
        console.log("Clic updated");
        this.createTittle();
      },
      error=>{
        this._snackBar.open("Error al eliminar el titulo de portada.", "Cerrar", {
          duration: 5000,
        });
      }
    );
  }
  deleteEffect(effect_id){
    this._projectsService.deleteEffect(effect_id).subscribe(
      success=>{
        console.log("Clic updated");
      },
      error=>{
        this._snackBar.open("Error al eliminar el efecto.", "Cerrar", {
          duration: 5000,
        });
      }
    );
  }
  getFiles(){
    this._projectsService.getFiles(this.project.id).subscribe(
      success=>{
        let success_body:any = success; 
        for(let i=0;i<success_body.body.results.length;i++){
            let media = success_body.body.results[i].media;
            let name = media.split("/")[7];
            let title = "";
            if(name=="defensa.png"){
              title="Defensa";
            }
            if(name=="delantero.png"){
              title="Delantero";
            }
            if(name=="volante.png"){
              title="Volante";
            }
            if(name=="arquero.png"){
              title="Arquero";
            }
            if(name=="defensa.png" || name=="delantero.png" || name=="volante.png" || name=="arquero.png"){
              let cover = {
                url:success_body.body.results[i].url,
                media:success_body.body.results[i].media,
                title:title
              }
              this.cover_pages.push(cover);
            }
            /*
            setTimeout(() => {
              
            }, 500);  */
         }
         this.loader = false;
         this.getClips();
      },
      error=>{
        this._snackBar.open("Error al cargar las fuentes", "Cerrar", {
          duration: 5000,
        });
      }
    );
  }
}
