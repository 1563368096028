import { Component, OnInit, ChangeDetectorRef, ViewEncapsulation, OnDestroy } from '@angular/core';
import { MediaMatcher } from '@angular/cdk/layout';
import { Router } from '@angular/router';

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: [ './help.component.scss']
})
export class HelpComponent implements OnInit {

  constructor(private router: Router) {
   
   }

  ngOnInit() {
    
  }
  backToEditorOrProject(){
    let project = JSON.parse(localStorage.getItem("project"));
    console.log(JSON.parse(localStorage.getItem("project")));
    if(project == null){
      this.router.navigate(["./projects"]);
    } else {
      let route = './editor/'+project.id+'/';
      console.log(route);
      this.router.navigate([route]);
    }
  }
}
