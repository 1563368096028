import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ProjectsService } from '../../../services/projects/projects.service';
import { Clic, Effect} from '../../../models/editor';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-delete-entity',
  templateUrl: './delete-entity.component.html',
  styleUrls: ['./delete-entity.component.css'],
  
})
export class DeleteEntityComponent implements OnInit {
  private clip:Clic;
  public loader:boolean;
  constructor(
    public _snackBar: MatSnackBar,
    private _projectsService:ProjectsService,
    public dialogRef: MatDialogRef<DeleteEntityComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { 
    this.clip = new Clic();
  }

  // inciar el componente
  ngOnInit() {
    this.clip = this.data.clip;
  }

  // cancelar el eliminado y cerrar componente
  cancelDelete(){
    this.dialogRef.close();
  }

  // ejecutar el eliminado
  delete(){
    this.deleteClip();
  }

  // borrar el clip principal
  deleteClip(){
    this.loader = true;
    this._projectsService.deleteClip(this.clip.id).subscribe(
      success=>{
        if(this.data.type_clip=="is_video"){
          if(this.clip.json.is_marker==true){
            this.deleteMarker();
          }else{
            this.deleteEffect();
          }
        }else{
          Swal.fire({
            type: "success",
            text: "El clip ha sido eliminado."
          });
          //this.loader=false;
            
        }
      },
      error=>{
        console.log(error);
        this.loader=false;
      }
    );
  }

  // eliminar el efecto de transicion
  deleteEffect(){
      this._projectsService.deleteEffect(this.clip.json.effect_transition.id).subscribe(
        success=>{
          
          Swal.fire({
            type: "success",
            text: "El clip ha sido eliminado."
          });
          
          //this.deleteEffect();
          this.dialogRef.close({id_clip_deleted:this.clip.id, type_clip:this.data.type_clip});
          //this.loader = false;
        },
        error=>{
          console.log(error);
          this.loader=false;
        }
      );
  }

  // eliminar el clip del marker
  deleteMarker(){
    if(this.clip.json.marker_setting.id_clip_marker!=undefined && this.clip.json.marker_setting.id_clip_marker!=0){
      this._projectsService.deleteClip(this.clip.json.marker_setting.id_clip_marker).subscribe(
        success=>{
          /*
          Swal.fire({
            type: "success",
            text: "El clip ha sido eliminado."
          });*/
          this.deleteEffect();
          this.dialogRef.close({id_clip_deleted:this.clip.id, type_clip:this.data.type_clip});
          this.loader = false;
        },
        error=>{
          console.log(error);
        }
      );
    }else{
      Swal.fire({
        type: "success",
        text: "El clip ha sido eliminado."
      });
      this.loader=false;
      this.dialogRef.close({id_clip_deleted:this.clip.id, type_clip:this.data.type_clip});
    }
  }
}
