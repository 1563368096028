import { Component, OnInit, ViewChild, Input, ChangeDetectorRef, ViewEncapsulation } from '@angular/core';
import { Router, Params, ActivatedRoute } from '@angular/router';
import { Project } from '../../models/project';
import { ProjectsService } from '../../services/projects/projects.service';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Options } from 'ng5-slider';
import { Source } from '../../models/source';
import { SourceService } from '../../services/source/source.service';
import { VgAPI,VgMedia } from 'videogular2/compiled/core';
import { Clic, File, Effect, Event, Exports } from '../../models/editor';

import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import { MatSelect } from '@angular/material/select';;

import { AdjusmentClipComponent } from './adjusment-clip/adjusment-clip.component';

import { AdjustTransitionComponent } from './adjust-transition/adjust-transition.component';

import { GetMarkerComponent } from './get-marker/get-marker.component';

import { Observable, Subject } from 'rxjs';

import { BrokerService } from '../../services/broker/broker.service';

import { AddEventComponent } from './add-event/add-event.component';

import { CoverPageComponent } from './cover-page/cover-page.component';

import * as uuid from 'uuid';

import {DeleteEntityComponent} from './delete-entity/delete-entity.component';

import { AddSourceToProjectComponent } from './add-source-to-project/add-source-to-project.component';

import {AuthService} from '../../services/auth/auth.service';

import { AdjusmentAudioClipComponent } from './adjusment-audio-clip/adjusment-audio-clip.component';

import { AdjustTimeCutterComponent } from './adjust-time-cutter/adjust-time-cutter.component';

import { MediaMatcher } from '@angular/cdk/layout';

import { DeviceDetectorService } from 'ngx-device-detector';

import Swal from 'sweetalert2';

@Component({
  selector: 'app-editor',
  templateUrl: './editor.component.html',
  styleUrls: ['./styled-slider.component.scss']
})
export class EditorComponent implements OnInit {
  private project_id:number;// id del proyecto a cargar
  private project:Project;// carga proyecto si viene de cargar nuevamente

  //variables de slider de rango de corte
  private minValue: number = 0;
  private maxValue: number = 100;
  private options_cut_video_editor: Options = {
    floor: 0,
    ceil: 0,
    step: 1,
    noSwitching: true,
    disabled: true,
    minRange: 2,
    maxRange: 40,
    pushRange: true
  };

  //variables de slider de rango de video para extraer corte
  private minValueRange: number = 0;
  private maxValueRange: number = 100;
  private options_range_video_editor: Options = {
    floor: 0,
    ceil: 100,
    step: 1,
    noSwitching: true,
    minRange: 120,
    maxRange: 600,
    disabled: true,
    pushRange: true
  };

  value: number = 3;
  options_speed_time_line: Options = {
    showTicksValues: true,
    stepsArray: [
      {value: 1, legend: '1FPS'},// 1 frame por segundo
      {value: 2, legend: '1FP10S'},// 1 frames por 10 second
      {value: 3, legend: '1FPC'}, // un frame por clip
    ]

  };

  private source_on_edit:Source;

  private api:VgAPI;

  private api2:VgAPI;

  private floor_range_indicator_video:string;
  private ceil_range_indicator_video:string;

  private floor_range_indicator:string;
  private ceil_range_indicator:string;

  private floor_range_indicator_seconds:string;
  private ceil_range_indicator_seconds:string;

  private clip:Clic;

  private files:File[];

  private audio_files:File[];

  public timePeriods = [
    'Clic 1',
    'Clic 2',
    'Clic 3'
  ];
  private transitions = [];

  private markers = []

  public clics:Clic[];

  public audio_clics:Clic[];

  public size_thumbnail:number; // representacion de cada thumnbnail en pixeles

  public size_second_on_pixels:number;

  public zoom_level:number;// 3 para 1 thumbnail por clic, 2 para 1 thumbnail por 10 segundos, 1 para 1 por segundo 

  //public time_video:number;// tiempo del video en minutos

  //@ViewChild('time_video') time_video: MatSelect; // si es configurable

  public time_video:number;// el tiempo total del video

  public time_video_on_pixels:number;//variable utilizada para representar el largo del video en pixeles

  public current_clip:number;// auxiliar para ayudar a transportar el clip y ejecutar acciones sobre el mismo

  public timeline_rule:any[];// alamacena los objetos para renderizar la regla de tiempo

  public request:any[];// almacena los request realizados a la API

  public timer:any;// ayudan a controlar el numero de vecese simular el doble clic en el mouse

  public preventSimpleClick:boolean;// variable para simular el doble clic en el mouse

  private event_list:any;// lista de eventos a eviar al previsualizador

  private factor_to_solve_transition:number;// el factor que se suma al clip para realziar correctamente la transicion

  private stop_request:boolean;// variable para controlar las respuestas de las peticiones de thumnails realizadas al servidor

  private fps:number;// frames per seconds del proyecto

  private loader:boolean;// indicador de precarga de datos

  private events:Event[];// eventos listados en la interfaz grafica

  private disabled_options:boolean;// variable para activar las accciones sobre el clip

  private audio_clip:Clic;

  private initReproductorTime:string;

  private currentReproductorTime:string;

  private firstTime1:number;
  private firstTime2:number;

  private export_video:boolean;

  private current_media;

  public fullScreen:boolean; // validar si la pantalla esta full screen

  public input_range_video_clip:boolean;

  //-------------Movil-------------
  mobileQuery: MediaQueryList;
  private _mobileQueryListener: () => void;

  //----VideoPlayers----
  public videoplayerpreview: boolean;
  public videoplayerClips: boolean;

  //----------Video Audio eventos
  public showVideos: boolean;
  public showAudios: boolean;
  public showEvents: boolean;

  public btnShowPreview: boolean;
  public isMobile:boolean;
  public preload_project: boolean;

  public water_mark_file:File;

  public water_mark:Clic;

  public signed_media:string;

  public last_duration_audio_clip:number;

  private tooltipRange:string;

  private canCut:boolean;
  private canCut_2:boolean;

  constructor(
    private _route:ActivatedRoute,
    private _router:Router,
    private _projectsService:ProjectsService,
    private _sourceService:SourceService,
    public dialog: MatDialog,
    public _brokerService:BrokerService,
    private _authService:AuthService,
    changeDetectorRef: ChangeDetectorRef, media: MediaMatcher,
    private deviceService: DeviceDetectorService,
  ) { 
    this.mobileQuery = media.matchMedia('(max-width: 770px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);

    //---Variables para ocultar
    this.videoplayerpreview = false;
    this.videoplayerClips = true;
    this.preload_project = true;
   //----------Video Audio eventos
   this.showAudios = false;
   this.showEvents = false;
   this.showVideos = false;
   this.btnShowPreview = true;
   //----Camilo
    this.source_on_edit = new Source();
    this.source_on_edit.media = "";
    this.floor_range_indicator = "00:00:00";
    this.ceil_range_indicator =  "00:00:00";
    this.clip = new Clic();
    this.files = [];
    this.clics = [];
    this.size_thumbnail = 64;//288 widht
    this.size_second_on_pixels = 10;
    this.zoom_level = 3;
    this.current_clip=0;
    this.timeline_rule=[];
    this.request=[];
    this.event_list = [];
    this.factor_to_solve_transition = 0.05;
    this.fps = 30;
    this.stop_request=false;
    this.loader=false;
    //this.control_to_thumnails_flow=0;
    this.events=[];
    this.disabled_options=true;
    this.audio_files = [];
    this.audio_clics = [];
    this.audio_clip = new Clic();
    this.initReproductorTime = "00:00:00";
    this.currentReproductorTime = "00:00:00";
    this.firstTime1=0;
    this.firstTime2=0;
    this.export_video=false;
    this.water_mark_file = new File();
    this.water_mark = new Clic();
    this.fullScreen=false;
    this.tooltipRange = "Ajusta el rango del segmento de video " + this.floor_range_indicator + " hasta " + this.ceil_range_indicator;
    this.input_range_video_clip=true;
    this.canCut = false;
    this.canCut_2 = false;
  }

  ngOnInit() {
    this.loader=true;
    this.defineDevice();
    setTimeout(() => {
      this.getProjectId();
      this.listenAddClipCoverPageModules();
      this.listenRefreshFiles();
      //this.listenRefreshEvents();
      this.listenResetBtnPlay();
    }, 300);
    
    //this.structureTimeLineRule(4)
    
  }

  defineDevice(){
    this.isMobile = this.deviceService.isMobile();
    //this.isMobile = true;
  }

  ngOnDestroy() {
    //this._brokerService.getCoverPageModule().unsubscribe();
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }

  formatPixelsShortTimeLine(){
    this.time_video_on_pixels = this.time_video * 60 * 5;
  }

  formatPixelsMediumTimeLine(){
    this.time_video_on_pixels = this.time_video * 6 * this.size_thumbnail;
    this.time_video_on_pixels = this.time_video_on_pixels+10;
  }

  formatPixelsLargeTimeLine(){
    this.time_video_on_pixels = this.time_video * 60 * this.size_thumbnail;
  }

  getProjectId(){
    this._route.params.forEach((params:Params)=>{
      this.project_id=params["project_id"];
      if(this.project_id!=undefined){
        this.loadProject();
      }else{
        this.projectNotExist();
      }
    });
  }

  loadProject(){
    this._projectsService.getProject(this.project_id).subscribe(
      success=>{
        let success_body:any = success; 
        this.project = success_body;
        this.preload_project = false; 
        this._projectsService.setProjectOnStorage(this.project);
        this.time_video = this.project.json.large_video;
        this.events = this.project.json.events;
        this.structureEvents();
        this.getFiles();
        this.getDataClic();
        this.formatPixelsShortTimeLine();
      },
      error=>{
        //this._snackBar.open("Error al cargar el proyecto", "Cerrar", {
        //  duration: 5000,
        //});
        Swal.fire({
          type: "error",
          text: "Error al cargar el proyecto"
        });
        this._router.navigate([''])
      }
    );
  }

  getFiles(){
    this.files = [];
    this.audio_files = [];
    this._projectsService.getFiles(this.project.id).subscribe(
      success=>{
        let success_body:any = success; 
        for(let i=0;i<success_body.body.results.length;i++){
            let media = success_body.body.results[i].media;
            let name = media.split("/")[7];
            if(success_body.body.results[i].json.media_type=="video" && name!="mira.mov"){
              this.files.push(success_body.body.results[i])
            }
            if(success_body.body.results[i].json.media_type=="audio"){
              this.audio_files.push(success_body.body.results[i])
            }
        }
      },
      error=>{
        //this._snackBar.open("Error al cargar las fuentes", "Cerrar", {
        //  duration: 5000,
        //});
        Swal.fire({
          type: "error",
          text: "Error al cargar las fuentes"
        });
        this._router.navigate([''])
      }
    );
  }

  getDataClic(){
    if(this.project.clips.length > 0){
      let route:any = this.project.clips[0].split('/');
      this.project.clips.splice(0,1);
      route = "clips/"+route[4]+"/";
      let request = this._projectsService.getClic(route).subscribe(
        success=>{
          let clip:any = success;
          clip = clip.body;
          //if(!this.isMobile){
          if(this.isMobile){
            clip.pixels_lenght = 0;
          }else{
            clip.pixels_lenght = this.buildTimeLineShotZoom(clip);
          }
          
          clip.json.file = clip.file;
          if(clip.json.is_audio){
            this.audio_clics.push(clip);
          }else if(clip.json.cover_page_tittle==undefined && clip.json.is_market_video==undefined){
            this.clics.push(clip);
          }else if(clip.json.is_water_mark==undefined && clip.json.is_water_mark==true){
            this.water_mark = clip;
          }

          this.getDataClic();
        },
        error=>{
          console.log(error);
        }
      );
      this.request.push(request);
    }else{
      this.sortClics();
    }
  }

  // detiene todas las peticiones cuando se hace un cambio de interfaz
  stopRequest(){
    if(this.request != undefined && this.request.length>0){
      for(let i=0; i<this.request.length;i++){
        if(this.request[i]){
          this.request[i].unsubscribe();
        }
      }
    }
  }

  // metodo utilizado para definir short timeline 
  buildTimeLineShotZoom(clip){
    this.size_second_on_pixels = 5;
    let large_pixels_by_item =  this.size_second_on_pixels;
    let pixels_lenght = (clip.end - clip.start) * large_pixels_by_item;
    return pixels_lenght;
    
  }

  // metodo utilizado para definir medium timeline 
  buildTimeLineMediumZoom(clip){
    let large_pixels_by_item =  this.size_thumbnail;
    let segment = clip.end - clip.start;
    segment = segment / 10;
    //let pixels_lenght = Math.round(segment) * this.size_thumbnail;
    let pixels_lenght = segment * this.size_thumbnail;
    return pixels_lenght;
  }

  // metodo utilizado para definir large timeline 
  buildTimeLineLargeZoom(clip){
    let pixels_lenght = (clip.end - clip.start) * this.size_thumbnail;
    return pixels_lenght;

  }

  // Informa que el proyecto no existe y redirige a la raiz 
  projectNotExist(){
    this.loader=false;
    //this._snackBar.open("El proyecto no existe", "Cerrar", {
    //  duration: 5000,
    //});
    Swal.fire({
      type: "error",
      text: "Error proyecto no existe"
    });
    setTimeout(() => {
      this._router.navigate(['/']);
    }, 1000);
  }

 // carga la fuente seleccionada
 loadSource(video_source_id){
  this.event();
  for(let i=0; i <this.files.length; i++){
    if(this.files[i].id==video_source_id){
      this.loadOnEditClip(this.files[i]);
      break;
    }
  }
 }

 // cargar siguiente fuente en el asistente de cortado de video 
 nextSource(){
  this.event();
  if(this.source_on_edit.id!=undefined){
    for(let i=0; i <this.files.length; i++){
      if(this.files[i].id==this.source_on_edit.id){
        if(this.files.length == (i+1)){
          this.loadOnEditClip(this.files[0]);
        }else{
          this.loadOnEditClip(this.files[i+1]);
        }
      }
    }
  }
 }

 // cargar anterior fuente en el asistente de cortado de video 
 backSource(){
  if(this.source_on_edit.id!=undefined){
    for(let i=0; i <this.files.length; i++){
      if(this.files[i].id==this.source_on_edit.id){
        if(0 == i){
          this.loadOnEditClip(this.files[this.files.length-1]);
        }else{
          this.loadOnEditClip(this.files[i-1]);
        }
      }
    }
  }
 }

 event(){

 }

 makeSigned(media){
  return media.replace("media","signed");
 }

// actualiza el tiempo de 
 loadOnEditClip(source_choosen){
    if(this.source_on_edit!=undefined){
      this.source_on_edit = source_choosen;
      let token=this._authService.getStoragedToken();
      this.source_on_edit.media = source_choosen.media;
      let signed = this.makeSigned(this.source_on_edit.media);
      this.signed_media = signed +"?Authorization="+token;
      (<VgMedia>this.api.getMasterMedia()).loadMedia();
      this.api.getDefaultMedia().subscriptions.loadedMetadata.subscribe(() => {
        this.setTimeToSlideRange(this.source_on_edit); 
        this.setUpFullTime(this.source_on_edit);
        this.setUpCurrentTime();
      });
    }else{
      Swal.fire({
        type: "error",
        text: "Error al cargar la fuente en editor"
      });
      //this._snackBar.open("Error al cargar la fuente en editor", "Cerrar", {
      //  duration: 5000,
      //});
    }
 }

 setUpFullTime(file){
  this.initReproductorTime = this.secondsToHMS(parseInt(file.json.duration));
 }

 setUpCurrentTime(){
  setInterval(() => {
    let current_time = this.api.currentTime;
    this.currentReproductorTime = this.secondsToHMS(parseInt(current_time));
  }, 500);
 }


 enableRangeSlide(): void {
  // Due to change detection rules in Angular, we need to re-create the options object to apply the change
  this.options_range_video_editor.disabled = false;
  const newOptions: Options = Object.assign({}, this.options_range_video_editor);
  this.options_range_video_editor = newOptions;
}


 setTimeToSlideRange(file){
  this.setNewLimitsRange(Math.round(file.json.duration));
 }

// se envia nuevo limte del techo del slide
setNewLimitsRange(newCeil: number): void {
  // Due to change detection rules in Angular, we need to re-create the options object to apply the change
  this.options_range_video_editor.disabled = false;
  let newOptions: Options = Object.assign({}, this.options_range_video_editor);
  newOptions.ceil = newCeil;
  this.options_range_video_editor = newOptions;
  this.maxValueRange = newCeil;
  this.minValueRange = 0;
  //this.ceil_range_indicator_seconds = String(this.maxValue);
  //this.ceil_range_indicator = this.secondsToHMS(this.maxValue);
  //this.highValueChange(this.maxValue, true);
}

 // 
 /*
 define_total_time(){
   let times = 0;
  let control_time = setInterval(() => {
    if(!isNaN(this.api.time.total) && this.api.time.total>0){
      let init_time=this.api.time.total/60000;
      let number_on_array = String(init_time).split('.');
      let decimal_real = parseInt(number_on_array[1])
      let decimals_adjust = parseInt(number_on_array[1])*0.583;
      decimals_adjust = Math.round(decimals_adjust);
      let decimals_adjust_format = "";

      if(String(decimal_real).length > String(decimals_adjust).length ){
        decimals_adjust_format = "0"+decimals_adjust;
      }else{
        decimals_adjust_format = String(decimals_adjust);
      }

      let final_time = parseFloat(number_on_array[0] + "." + decimals_adjust_format);
      final_time = this.roundTo(final_time,2);
      this.minutesToSeconds(final_time);
      clearInterval(control_time);
    }
  }, 200);
 }
 */

 // redondea los decimales de un numero
roundTo(n, digits) {
  var negative = false;
  if (digits === undefined) {
      digits = 0;
  }
  if( n < 0) {
    negative = true;
    n = n * -1;
  }
  var multiplicator = Math.pow(10, digits);
  n = parseFloat((n * multiplicator).toFixed(11));
  n = (Math.round(n) / multiplicator).toFixed(2);
  if( negative ) {    
    n = (n * -1).toFixed(2);
  }
  return n;
}

/*
// pasar minutos a segundos
minutesToSeconds(minutes){
  let get_minutes = minutes.split('.');
  let seconds = (parseInt(get_minutes[0])*60) + parseInt(get_minutes[1])
  this.setNewCeil(seconds);
}
*/
// se envia nuevo limte del techo del slide
setNewCeil(newCeil: number): void {
  console.log("setNewCeil");
  // Due to change detection rules in Angular, we need to re-create the options object to apply the change
  this.options_cut_video_editor.disabled = false;
  const newOptions: Options = Object.assign({}, this.options_cut_video_editor);
  newOptions.ceil = newCeil;
  this.options_cut_video_editor = newOptions;
  this.maxValue = newCeil;

  this.ceil_range_indicator_seconds = String(this.maxValue);
  this.ceil_range_indicator = this.secondsToHMS(this.maxValue);
  //this.highValueChange(this.maxValue, true);
  this.disabled_options=true;
  this.tooltipRange = "Ajusta el rango del segmento de video desde " + this.floor_range_indicator + " hasta " + this.ceil_range_indicator;
}

// se envia nuevo limte del techo del slide
setNewFloor(newFloor: number): void {
  console.log("setNewFloor");
  // Due to change detection rules in Angular, we need to re-create the options object to apply the change
  this.options_cut_video_editor.disabled = false;
  const newOptions: Options = Object.assign({}, this.options_cut_video_editor);
  newOptions.floor = newFloor;
  this.options_cut_video_editor = newOptions;
  this.minValue = newFloor;

  this.floor_range_indicator_seconds = String(this.minValue);
  this.floor_range_indicator = this.secondsToHMS(this.minValue);
  this.tooltipRange = "Ajusta el rango del segmento de video " + this.floor_range_indicator + " hasta " + this.ceil_range_indicator;
}

disabledCutterSlider(){
  this.options_cut_video_editor.disabled = true;
  const newOptions: Options = Object.assign({}, this.options_cut_video_editor);
  this.options_cut_video_editor = newOptions;
}

// metodo que se carga al estar listo el reproductor para realizar recortes
onPlayerReady(api:VgAPI) {
  this.api = api;
  this.api.fsAPI.onChangeFullscreen.subscribe(
        event => {
            this.onChangeFullscreen(event);
        }
    );
}

onChangeFullscreen(event){
  this.fullScreen = event;
}

//metodo para reproducir el video
playVideo(api:VgAPI) {
  this.api.play();
}

//metodo para pausar al video
pauseVideo(api:VgAPI) {
  this.api.pause();
}

// metodo que se carga al estar listo el reproductor para previsualizar
onPlayerReadyPreview(api:VgAPI) {
  this.api2 = api;
}

// cambia valor inferior del slider para definir rango de extraer clip
valueChangeRange(event){
    this.disabled_options=false;
    this.floor_range_indicator_video = event;
    this.setNewFloor(parseInt(this.floor_range_indicator_video));
    this.disabledCutterSlider();
}

// cambia valor superior del slider para definir rango de extraer clip
highValueChangeRange(event){
  this.disabled_options=false;
  this.ceil_range_indicator_video = event;
  this.setNewCeil(parseInt(this.ceil_range_indicator_video));
}
// cambia valor inferior del slider para definir rago de corte de clip
valueChangeCut(event){
  //debugger;
  if(event!=0){
    this.canCut = true;
    this.floor_range_indicator_seconds = event;
    this.floor_range_indicator = this.secondsToHMS(event);
  }else{
    this.floor_range_indicator_seconds = '0';
    this.floor_range_indicator = this.secondsToHMS('0');
  }
}
// cambia valor superior del slider para definir rago de corte de clip
highValueChangeCut(event){
  //debugger;
  if(event!=0){
    this.canCut_2 = true;
    this.ceil_range_indicator_seconds = event;
    this.ceil_range_indicator = this.secondsToHMS(event);
  }else{
    this.ceil_range_indicator_seconds = '0';
    this.ceil_range_indicator = this.secondsToHMS('0');
  }
}

// Pasar de segundos a horas, minutos y segundos
secondsToHMS(seconds){
  if(seconds!=NaN){
    var measuredTime = new Date(null);
    measuredTime.setSeconds(seconds); // specify value of SECONDS
    var MHSTime = measuredTime.toISOString().substr(11, 8);
    return MHSTime;
  }  
}

// pasa de formato de horas minutos y segundos a solo segundos
HMSToSeconds(hms){
  // your input string
  var a = hms.split(':'); // split it at the colons
  // minutes are worth 60 seconds. Hours are worth 60 minutes.
  var seconds = (+a[0]) * 60 * 60 + (+a[1]) * 60 + (+a[2]); 
  return seconds;
  }

  /*
  // cambia el minimo del slider segun la fuente
  changeFloorInput(event){
    let seconds = this.HMSToSeconds(event);
    this.floor_range_indicator = String(seconds);
  }
  */

  /*
 //cambia el largo del slider segun el tamaño de la fuente 
  changeCeilInput(event){
    let seconds = this.HMSToSeconds(event);
    this.ceil_range_indicator = String(seconds);
  }
  */
  clicFloorInput(){
    console.log(this.floor_range_indicator);
  }
  clicCeilInput(event){
    console.log(this.ceil_range_indicator);
  }
  
  // corta el clic y lo envia a la API
  cutClip(is_to_marker_param){
   // debugger;
    if(this.canCut && this.canCut_2){
      if(this.validateClic()){
        this.loader=true;
        this.clip.project = this.project.url;
        this.clip.position = this.validatePositionClic();
        this.clip.start = parseInt(this.floor_range_indicator_seconds);
        this.clip.end = parseInt(this.ceil_range_indicator_seconds);
        let new_segment = this.clip.end  - this.clip.start;
        if(this.validateDurationClip(new_segment)){
          this.clip.layer = 1;
          let body_marker = {};
          if(is_to_marker_param){
            body_marker = this.calcMarkerPositionConfig(this.clip);
            this.clip.end = this.clip.end + 2;// se agrega 2 segundo del freeze del marcador
          }
          this.clip.json={
            speed:"1",
            real_end: this.clip.end,
            is_marker:is_to_marker_param,
            marker_setting:body_marker
          };
          this.clip.file = this.source_on_edit.url;
          this.clip.json.media_file = this.source_on_edit.media;
          
          this._projectsService.createClip(this.clip).subscribe(
            clip=>{
              
              Swal.fire({
                type: "success",
                text: "Clip cortado"
              });
              this.createEvent(clip);
              this.structureEffectTransition(clip);
            },
            error=>{
              Swal.fire({
                type: "error",
                text: "Error al cortar el clip"
              });
            }
          );
        }
      }
    }else{
      Swal.fire({
        type: "warning",
        text: "Debe seleccionar el rango del videoclip"
      });
    }
  }

  calcMarkerPositionConfig(clip){
    let body_marker_config = {
      //current_second:1,// segundo en donde se encuentra el amrker
      //current_fps:1,// posicion relativa del marker en el segundo
      fps_position:0,// fps seleccionado
      zoom_marker:0,// 
      position_marker_x:0,// 
      position_marker_y:0,
      current_time:clip.start,
      id_clip_marker:0,
      value_slide_zoom:0
    }
    return body_marker_config;
  }

  //valida si el usuario ya cargo una fuente para poder extraer el clic
  validateClic(){
    let validate = true;
    let diference = this.maxValue - this.minValue;
    if(diference > 40){
      Swal.fire({
        type: "warning",
        text: "Debe ajustar el lago de video clip a 40 segundos máximo."
      });
      validate=false;
    }
    /*
    
    if(this.options_cut_video_editor.disabled==true){
      validate = false;
      Swal.fire({
        type: "warning",
        text: "Antes de extraer el clip, debes seleccionar una fuente de video."
      });
      //this._snackBar.open("Antes de extraer el clip, debes seleccionar una fuente de video.", "Cerrar", {
      //  duration: 5000,
     // });
    }
    */
    return validate;
  }

  validateDurationClip(new_segment:number){
    let validate= true;
    let summatory = new_segment;
    for(let i=0; i<this.clics.length;i++){
      summatory = summatory + (this.clics[i].end - this.clics[i].start);
    }
    if(summatory>600){
      validate = false;
      //this._snackBar.open("No es posible agregar el clip, ya que sobrepasa el limite del tiempo del video.", "Cerrar", {
      //  duration: 5000,
      //});
      Swal.fire({
        type: "error",
        text: "No es posible agregar el clip ya que sobrepasa el limite del tiempo del video."
      });
      this.loader=false;
    }
    return validate;
  }
  
  // agregar el clic al array para que sea renderizado en la interfaz de usuario
  addClicToInterface(clip){
    clip.json.file = clip.file;
    this.clics.push(clip);
    if(this.zoom_level==3){
      this.formatOneThumbnailPerClip(clip);
    }else if(this.zoom_level==2){
      this.formatOneThumbnailPerTenSecondClip(clip);
    }else if(this.zoom_level==1){
      this.formatOneThumbnailPerSecondClip(clip);
    }
    this.getThumnailClic(clip);
  }
  
  // get thumnail
  getThumnailClic(clic){
    if(clic.thumnails_position.length>0){
      let file = clic.json.file.split("/");
      let request = this._projectsService.getThumnailsClip(file[4],clic.thumnails_position[0]).subscribe(
        success=>{
          let image = this.createImageFromBlob(success, clic);
          clic.thumnails_position.splice(0,1);
          this.getThumnailClic(clic);
        },error=>{
          //this._snackBar.open("Error al cargar el thumnail.", "Cerrar", {
          //  duration: 5000,
          //});  
          Swal.fire({
            type: "error",
            text: "Error al cargar el thumnail."
          });    
        }
      );
      this.request.push(request);
    }
  }
  
  // Genera la imagen por blob
  createImageFromBlob(image: Blob, clic) {
    let reader = new FileReader();
    let image_result:any;
    reader.addEventListener("load", () => {
      image_result = reader.result;
      if((clic.thumnails_short!=undefined && clic.thumnails_medium!=undefined && clic.thumnails_large!=undefined) || clic.thumnails_mobile!=undefined){
        
        if(this.isMobile){
          clic.thumnails_mobile.push(image_result);
        }else{
          if(this.zoom_level==1){
            clic.thumnails_large.push(image_result);
          }else if(this.zoom_level==2){
            clic.thumnails_medium.push(image_result);
          }else if(this.zoom_level==3){
            clic.thumnails_short.push(image_result);
          } 
        }  
      }
    }, false);
    if (image) {
       reader.readAsDataURL(image);
    }
    return image_result;
  }
  
  // valida y define la posicion del clic al crearlo
  validatePositionClic(){
    let position_last_clip = 0; // primer clip 0.1
    if(this.clics.length > 0){
      // segment = 40 - 0
      // segment = 40
      let segment = this.clics[this.clics.length-1].end - this.clics[this.clics.length-1].start;
      //  0.1 
      position_last_clip = (this.clics[this.clics.length-1].position) + segment;
      position_last_clip = position_last_clip + this.factor_to_solve_transition;
    }
    return position_last_clip;
  }

  // ordena los clics depues de traerlos de la API
  sortClics(){
    this.loader=true;
    if(this.clics.length>0){
      this.clics.sort(function(a, b) {
        return a.position - b.position;
      });
      //this.getThumnailsOfRegisterClips(0);
      this.defineStructureTimeLine();
      this.sortTransitions();
      this.reSortEvents();
    }else{
      this.loader = false;
    }
  }

  //utiliza metodo para cargar clips de mobiles
  structureMobileClips(){
    this.getFourThumbnailPerClip();
  }

  getFourThumbnailPerClip(){
    for(let i=0; i<this.clics.length; i++){
      this.formatFourThumbnailsPerClip(this.clics[i]);
    }
    this.getThumnailRegistersClic();
  }

  formatFourThumbnailsPerClip(clip){
    clip.thumnails_mobile = [];
    clip.thumnails_position = [];
    clip.thumnails_position = this.getFourThumnailPositionByClip(clip);
  }

  // calcula las posiciones de los thumnails cuando se cargan los clips
  getFourThumnailPositionByClip(clic){
    let frames_to_get_thumnails = [];
    let current_fps = this.fps;
    let start = clic.start;
    let end = clic.end;
    let dur = end - start;
    let four_parts = Math.floor(dur/4);

    let first_thumbnail = (1*1) + start;
    let second_thumbnail = (four_parts*1) + start;
    let third_thumbnail = (four_parts*2) + start;
    let four_thumbnail = (four_parts*3) + start;

    frames_to_get_thumnails.push(Math.floor(first_thumbnail*current_fps));
    frames_to_get_thumnails.push(Math.floor(second_thumbnail*current_fps));
    frames_to_get_thumnails.push(Math.floor(third_thumbnail*current_fps));
    frames_to_get_thumnails.push(Math.floor(four_thumbnail*current_fps));

    return frames_to_get_thumnails;
  }




  // define la estrcutura de la linea de tiempo
  defineStructureTimeLine(){
    this.resetCurrentCounter();
    if(this.isMobile){
      this.structureMobileClips();
    }else{
      if(this.zoom_level==3){
        this.formatPixelsShortTimeLine();
        this.getOneThumbnailPerClip();
        this.structureTimeLineRule(4, -8, 8);
        this.audioClipsShortLenght();
      }else if(this.zoom_level==2){
        this.formatPixelsMediumTimeLine();
        this.getOneThumbnailPerTenSecondClip();
        this.structureTimeLineRule(5.42, -6, 8);
        this.audioClipsMediumLenght();
      }else if(this.zoom_level==1){
        this.formatPixelsLargeTimeLine();
        this.getOneThumbnailPerSecondClip();
        this.structureTimeLineRule(63, 55, 4);
        this.audioClipsLargeLenght();
      }
    }
  }

  resetCurrentCounter(){
    this.current_clip = 0;
  }

  // estrcutura las posiciones de un thumnail
  getOneThumbnailPerClip(){
    for(let i=0; i<this.clics.length; i++){
      this.formatOneThumbnailPerClip(this.clics[i]);
    }
    this.getThumnailRegistersClic();
  }

  formatOneThumbnailPerClip(clip){
    clip.thumnails_short = [];
    clip.thumnails_medium = [];
    clip.thumnails_large = [];
    clip.thumnails_position = [];
    clip.thumnails_position = this.getInitThumnailPosition(clip);
    clip.pixels_lenght = this.buildTimeLineShotZoom(clip);
  }

  audioClipsShortLenght(){
    for(let i=0; i<this.audio_clics.length; i++){
      this.audio_clics[i].pixels_lenght = this.buildTimeLineShotZoom(this.audio_clics[i]);
    }
  }

  // generar posicion de 1 thumnail
  getInitThumnailPosition(clip){
    let frames_to_get_thumnails = [];
    let current_fps = this.fps;
    frames_to_get_thumnails.push(clip.start * current_fps)
    return frames_to_get_thumnails;
  }
  getOneThumbnailPerTenSecondClip(){
    for(let i=0; i<this.clics.length; i++){
      this.formatOneThumbnailPerTenSecondClip(this.clics[i]);
    }
    this.getThumnailRegistersClic();
  }

  formatOneThumbnailPerTenSecondClip(clip){
    clip.thumnails_short = [];
    clip.thumnails_medium = [];
    clip.thumnails_large = [];
    clip.thumnails_position = [];
    clip.thumnails_position = this.getThumnailPositionByTenSeconds(clip);
    clip.pixels_lenght = this.buildTimeLineMediumZoom(clip);
  }

  audioClipsMediumLenght(){
    for(let i=0; i<this.audio_clics.length; i++){
      this.audio_clics[i].pixels_lenght = this.buildTimeLineMediumZoom(this.audio_clics[i]);
    }
  }

  // calcula las posiciones de los thumnails cuando se cargan los clips
  getThumnailPositionByTenSeconds(clic){
    let frames_to_get_thumnails = [];
    let current_fps = this.fps;
    let start = clic.start;
    let end = clic.end;

    for(let i=start; i<end; i+=10){
      frames_to_get_thumnails.push(i*current_fps);
    }
    return frames_to_get_thumnails;
  }

  getOneThumbnailPerSecondClip(){
    for(let i=0; i<this.clics.length; i++){
      this.formatOneThumbnailPerSecondClip(this.clics[i]);
    }
    this.getThumnailRegistersClic();
  }

  formatOneThumbnailPerSecondClip(clip){
    clip.thumnails_short = [];
    clip.thumnails_medium = [];
    clip.thumnails_large = [];
    clip.thumnails_position = [];
    clip.thumnails_position = this.getThumnailPositionBySeconds(clip);
    clip.pixels_lenght = this.buildTimeLineLargeZoom(clip);
  }

  audioClipsLargeLenght(){
    for(let i=0; i<this.audio_clics.length; i++){
      this.audio_clics[i].pixels_lenght = this.buildTimeLineLargeZoom(this.audio_clics[i]);
    }
  }
  
  getThumnailPositionBySeconds(clic){
    let frames_to_get_thumnails = [];
    let current_fps = Math.round((clic.json.reader.fps.num / clic.json.reader.fps.den));
    let start = clic.start;
    let end = clic.end;

    for(let i=start; i<end; i++){
      frames_to_get_thumnails.push(i*current_fps);
    }
    return frames_to_get_thumnails;
  }
  
  // traer thumbnail de un clip
  getThumnailRegistersClic(){
    if(this.clics.length > 0){
      if(this.clics[this.current_clip].thumnails_position.length>0){
        let file = this.clics[this.current_clip].json.file.split("/");
        this._projectsService.getThumnailsClip(file[4],this.clics[this.current_clip].thumnails_position[0]).subscribe(
          success=>{
            if(this.stop_request==false){
              let image = this.createImageFromBlob(success, this.clics[this.current_clip]);
              if(this.clics[this.current_clip].thumnails_position!=undefined){
                this.clics[this.current_clip].thumnails_position.splice(0,1);
                this.getThumnailRegistersClic();
              }
            }
          },error=>{
            Swal.fire({
              type: "error",
              text: "Error al cargar el thumnail."
            }); 
           // this._snackBar.open("Error al cargar el thumnail.", "Cerrar", {
            //  duration: 5000,
            //});       
          }
        );
      }else{
        this.nextClip();
      }
    }
  }

  nextClip(){
    this.current_clip = this.current_clip + 1;
    if(this.clics.length>0 && (this.current_clip <= (this.clics.length-1))){
      this.getThumnailRegistersClic();
    }
  }
  

  // Evento drop para organizar los clics cuando el usuario los reorganiza
  drop(event: CdkDragDrop<string[]>) {
    //if(event.previousIndex!=event.currentIndex){
      this.loader= true;
      let is_dropable = this.validateDrop(event.currentIndex);
      if(is_dropable){
        moveItemInArray(this.clics, event.previousIndex, event.currentIndex);
        this.reOrderClips();
        this.sortTransitions();
      }else{
        this.loader= false;
      }
   // }
    
  }

  validateDrop(index){
    let can_drop = true;
    if(this.clics[index].json.cover_page){
      can_drop = false;
    }
    return can_drop;
  }

  /*
  // cuando el usuario cambia el tamaño del video
  changeTimeLineLarge(){
    this.time_video.valueChange.subscribe(
      value => {
        this.formatPixelsShortTimeLine();
        this.project.json.large_video = String(this.time_video.value);
        this.updateProject();
      }
    );
  }
  */
  // actauliza el propyecto en la API
  updateProject(){
    this.loader=true;
    this._projectsService.updateProject(this.project, this.project.id).subscribe(
      success=>{
        this.loader=false;
        let success_body:any = success; 
        this.project = success_body;
        this.events = this.project.json.events;
        this.structureEvents();
      },
      error=>{
        this.loader=false;
        //this._snackBar.open("Error al actualizar el clic.", "Cerrar", {
        //  duration: 5000,
        //});
        Swal.fire({
          type: "error",
          text: "Error al actualizar el clic."
        }); 
      }
    );
  }

  // reordena los clips cargados segun el inicio de cada uno
  reOrderClips(){
    this.loader=true;
    if(this.clics.length){
      for(let i=0; i<this.clics.length; i++){
        if(i==0){
          this.clics[i].position = 0;
        }else{
          let large_last_clip = this.clics[i-1].end - this.clics[i-1].start; 
          let last_postion = this.clics[i-1].position + large_last_clip;
          let current_position = last_postion + this.factor_to_solve_transition;
          this.clics[i].position = current_position;
        }
      }
      this.updateClipSorter(this.clics);
      this.structureUpdateEffectsSorter(this.clics);
      this.structureUpdatrMarkerSorter(this.clics);
    }
  }

    // reordena los clips cargados segun el inicio de cada uno
    reOrderAudioClips(){
      console.log("reOrderClips");
      this.loader=true;
      if(this.audio_clics.length>0){
        for(let i=0; i<this.audio_clics.length; i++){
          if(i==0){
            this.audio_clics[i].position = 0;
          }else{
            let large_last_clip = this.audio_clics[i-1].end - this.audio_clics[i-1].start; 
            let last_postion = this.audio_clics[i-1].position + large_last_clip;
            let current_position = last_postion;
            this.audio_clics[i].position = current_position;
          }
        }
        console.log(this.clics);
        this.updateAudioClipSorter(this.audio_clics);
      }
    }

  // ciclo que actualiza cada clip en la API cuando se cambian las posiciones
  updateClipSorter(clips){
    for(let i =0; i<clips.length; i++){
      this.updateClip(clips[i]);
    }
    setTimeout(() => {
      this.loader=false;
      this.reSortEvents();// reaordena los eventos despues de reordenar los clips
    }, 2000);
  }

  // ciclo que actualiza cada clip en la API cuando se cambian las posiciones
  updateAudioClipSorter(clips){
    for(let i =0; i<clips.length; i++){
      this.updateClip(clips[i]);
    }
  }

  // actauliza el clip en la API
  updateClip(clip){
    this._projectsService.updateClic(clip, clip.id).subscribe(
      success=>{
        console.log("Clic updated");
      },
      error=>{
       // this._snackBar.open("Error al actualizar el clic.", "Cerrar", {
        //  duration: 5000,
        //});
        Swal.fire({
          type: "error",
          text: "Error al actualizar el clic."
        });
        this.loader = false;
      }
    );
  }
  structureUpdatrMarkerSorter(clips){
    let markers = [];
    for(let i =0; i<clips.length; i++){
      if(clips[i].json.is_marker!=undefined && clips[i].json.is_marker==true && clips[i].json.marker_setting.id_clip_marker!=0){
        let segment = clips[i].end - clips[i].start;
        let position_marker = this.getClipMarkerPosition(clips[i].json.marker_setting.current_time, clips[i]);
        let marker = {
          id:clips[i].json.marker_setting.id_clip_marker,
          position: position_marker
        }
        markers.push(marker);
      }
    }
    this.updateSorterClips(markers);
  }
  updateSorterClips(markers){
    for(let i=0; i<markers.length;i++){
      let clip:Clic = new Clic();
      clip.id = markers[i].id;
      clip.position = markers[i].position;
      this.updateClip(clip);
    }
  }

  getClipMarkerPosition(current_time, clip){
    let relative_time = current_time - clip.start;
    let second = relative_time + clip.position;
    return second;
  }
  structureUpdateEffectsSorter(clips){
    let effects = [];
    for(let i =0; i<clips.length; i++){
      let segment = clips[i].end - clips[i].start;
      let position_effect = clips[i].position + segment - 1;
      if(clips[i].json.effect_transition != undefined){
        if(clips[i].json.effect_transition.id != undefined){
          let effect = {
            id:clips[i].json.effect_transition.id,
            position: position_effect
          }
          effects.push(effect);
        }
      }
    }
    this.updateSorterEffects(effects);
  }
  // lanza la actualizacion de cada efecto de transision
  updateSorterEffects(effects){
      for(let i=0; i<effects.length;i++){
        let effect:Effect = new Effect();
        effect.id = effects[i].id;
        effect.position = effects[i].position;
        this.updateEffect(effect);
      }
  }
  // actualiza efecto
  updateEffect(effect){
    this._projectsService.updateEffect(effect, effect.id).subscribe(
      success=>{
        console.log("Effect updated");
      },
      error=>{
        //this._snackBar.open("Error al actualizar la posición del efecto de transición.", "Cerrar", {
        //  duration: 5000,
        //});
        Swal.fire({
          type: "error",
          text: "Error al actualizar la posición del efecto de transición."
        });
      }
    );
  }

  //cambia el slider de zoom
  userChangeEnd(event){
    this.zoom_level = event.value;
    this.stop_request = true;
    this.loader=true;
    setTimeout(() => {
      this.loader=false;
      this.stop_request=false;
      this.defineStructureTimeLine();
    }, 2000);
    
  }

  //structura la regla de tiempo de la line de tiempo
  structureTimeLineRule(lenght_on_pixels, margin_left_number_indicator, ajusments_minutes){
    let counter_seconds=0;
    let current_minute = 1;
    this.timeline_rule=[];
    while (current_minute < 11){
      for(let i = 1;i<60;i++){
        counter_seconds++;
        let segment = {}
        if(i==10 || i==20 || i==30 || i==40 || i==50 ){
          segment = {
            "value":(current_minute-1)+":"+i,
            "lenght_on_pixels":lenght_on_pixels,
            "label":"SD",
            "height":5,
            "margin_left":margin_left_number_indicator,
            "second":counter_seconds,
            "event":null
          };
        }else if(i<10){
          segment = {
            "value":(current_minute-1)+":0"+i,
            "lenght_on_pixels":lenght_on_pixels,
            "label":"S",
            "height":2,
            "margin_left":margin_left_number_indicator,
            "second":counter_seconds,
            "event":null
          };
        }else{
          segment = {
            "value":(current_minute-1)+":"+i,
            "lenght_on_pixels":lenght_on_pixels,
            "label":"S",
            "height":2,
            "margin_left":margin_left_number_indicator,
            "second":counter_seconds,
            "event":null
          };
        }
        this.timeline_rule.push(segment);
      }
      counter_seconds++;
      let segment = {
        "value":current_minute,
        "lenght_on_pixels":lenght_on_pixels,
        "label":"M",
        "height":10,
        "margin_left":margin_left_number_indicator + ajusments_minutes,
        "second":counter_seconds,
        "event":null
      };
      this.timeline_rule.push(segment);
      current_minute = current_minute+1;
    }
    console.dir(this.timeline_rule);
  }

  // Define los eventos que van en la linea de tiempo
  makeTimeLineIndicatorLogic(init_clip:number){
    this.event_list = [];
    for(let i=init_clip; i<this.clics.length; i++){
      let type="clip";
      let saturation_body = this.defineSaturation(this.clics[i]);
      let audio_body = this.defineAudio(this.clics[i]);
      let transition = "";
      
      if(this.clics[i].json.effect_transition!=undefined){
        transition = this.clics[i].json.effect_transition.type;
      }else{
        transition = "fade";
      }
      let zoom_marker=0;
      let distance_x=0;
      let distance_y=0;
      let begin_at=0;
      let height_container_marker = 0;
      let width_container_marker = 0;
      if(this.clics[i].json.is_marker!=undefined && this.clics[i].json.is_marker==true){
        type = "marker";
        distance_x=this.clics[i].json.marker_setting.position_marker_x;
        distance_y=this.clics[i].json.marker_setting.position_marker_y;
        zoom_marker=this.clics[i].json.marker_setting.zoom_marker;
        begin_at=this.clics[i].json.marker_setting.current_time;
        height_container_marker=this.clics[i].json.marker_setting.height_container_marker;
        width_container_marker=this.clics[i].json.marker_setting.width_container_marker;
      }
      let cover = null;
      if(this.clics[i].json.cover_page!=undefined && this.clics[i].json.cover_page==true){
        cover = this.clics[i].json.media;
      }
      let event = {
        cover: cover,
        start:this.clics[i].start,
        end:this.clics[i].end,
        speed:this.clics[i].json.speed,
        saturation: saturation_body,
        audio:audio_body,
        transition:transition,
        media:this.clics[i].json.media_file,
        zoom_marker:zoom_marker,
        distance_x:distance_x,
        distance_y:distance_y,
        marker_begin_at:begin_at,
        type_event:type,
        width_container_marker:width_container_marker,
        height_container_marker:height_container_marker
      }

      this.event_list.push(event);
    }
  }
  // Define la saturacion de un clip para el evento
  defineSaturation(clip){
    let saturation=1;
    let effects = clip.json.effects;
    if(effects.length>0){
      for(let i=0;i<effects.length;i++){
        if(effects[i].class_name=="Saturation"){
          saturation = effects[i].saturation.Points[0].co.Y;
        }
      }
    }
    return saturation;
  }

  // Define el audio de un clip para el evento
  defineAudio(clip){
    let audio_video = true;
    let points = clip.json.volume.Points;
    if(points.lenght == 0 || points == undefined){
      //this._snackBar.open("Error al cargar el volumen del clip, se solucionará al aplicar nuevos ajustes sobre el clip.", "Cerrar", {
      //  duration: 5000,
      //});
      Swal.fire({
        type: "error",
        text: "Error al cargar el volumen del clip, se solucionará al aplicar nuevos ajustes sobre el clip."
      });
    }else if(points.length==1){
      audio_video=true;
    }else{
      let volumen_level = points[0].co.Y;
      if(volumen_level<1){
        audio_video=false;
      }else{
        audio_video=true;
      }
    }

    return audio_video;
  }
  

  // un clic para los items clip
  simpleClickFunction(): void{
    this.timer = 0;
    this.preventSimpleClick = false;
    let delay = 200;

    this.timer = setTimeout(() => {
      if(!this.preventSimpleClick){
        console.log("simple click on clip");
      }
    }, delay);

  }

  // doble clic para los items clip
  doubleClickFunction(id_clip): void{
    this.preventSimpleClick = true;
    clearTimeout(this.timer);
    //whatever you want with double click go here
    //this.settingsClip(id_clip);
    this.defineSettings(id_clip);
    console.log("double click");
  }

  defineSettings(clip_id){
    for(let i=0; i < this.clics.length; i++){
      if(this.clics[i].id == clip_id){
        let is_marker = this.typeClip(this.clics[i]);
        if(is_marker==1){
          this.getMaker(clip_id);
          break;
        }else if(is_marker==3){
          this.settingsClip(clip_id);
        }else{
          break;
        }
      }
    }
  }

  //lanza modulo de configuración de marker 
  getMaker(clip_id_param){
    const dialogRef = this.dialog.open(GetMarkerComponent,{
      height: '585x',
      width: '300px',
      data: { clip_id:clip_id_param, project:this.project}
    });
    dialogRef.afterClosed().subscribe(result => {
      this.updateMarkerSettins(result);
    });
  }

  updateMarkerSettins(result){
    if(result!=undefined){
      for(let i=0; i<this.clics.length;i++){
        if(result.data.id == this.clics[i].id){
          this.clics[i].json.marker_setting = result.data.json.marker_setting;
        }
      }
    }
  }

  typeClip(clip){
    let is_marker = 0;
    if( clip.json.is_marker != undefined && clip.json.is_marker){
      is_marker = 1;
    }else if(clip.json.cover_page != undefined && clip.json.cover_page){
      is_marker = 2;
    }else{
      is_marker = 3;
    }
    return is_marker;
  }

  // lanza el modal con el componente para ajustar un clip
  settingsClip(id_clip){
    const dialogRef = this.dialog.open(AdjusmentClipComponent,{
      height: '455x',
      width: '300px',
      data: { id: id_clip}
    });
    dialogRef.afterClosed().subscribe(result => {
      this.formatClipComeFromSettingsClip(result);
    });
  }

  //valida si hay ajustes de clip
  formatClipComeFromSettingsClip(result){
    if(result!=undefined){
      if(result.data.time_changed==true || result.data.volume_changed==true || result.data.saturation_changed==true){
        this.updateLocalClipFromSettings(result.data.clip);
      }
    } 
  }

  simpleClickFunctionTransition(): void{
    this.timer = 0;
    this.preventSimpleClick = false;
    let delay = 200;

    this.timer = setTimeout(() => {
      if(!this.preventSimpleClick){
        console.log("simple click on transition");
      }
    }, delay);
  }
  
  // doble clic para los items clip
  doubleClickFunctionTransition(effects, clip_id): void{
    this.preventSimpleClick = true;
    clearTimeout(this.timer);
    //whatever you want with double click go here
    this.settingsTransition(effects, clip_id);
  }


  // lanza el modal con el componente para ajustar una transición
  settingsTransition(effects, clip_id){
    const dialogRef = this.dialog.open(AdjustTransitionComponent,{
      height: '455x',
      width: '300px',
      data: { effects: effects, clip_id:clip_id}
    });
    dialogRef.afterClosed().subscribe(result => {
      this.formatClipComeFromSettingsTransition(result);
    });
  }

  //valida si hay ajustes de transicion
  formatClipComeFromSettingsTransition(result){
    if(result!=undefined){
      if(result.data.transition_changed==true){
        this.updateLocalClipFromSettingsTransition(result.data);
      }
    }
  }

  //actualiza clip en local
  updateLocalClipFromSettings(clip){
    for(let i=0; i<this.clics.length;i++){
      if(clip.id == this.clics[i].id){
        clip.json.file = this.clics[i].json.file;
        this.clics[i] = clip;
      }
    }
    if(this.zoom_level==3){
      clip.pixels_lenght = this.buildTimeLineShotZoom(clip);
      this.formatOneThumbnailPerClip(clip);
    }else if(this.zoom_level==2){
      clip.pixels_lenght = this.buildTimeLineMediumZoom(clip);
      this.formatOneThumbnailPerTenSecondClip(clip);
    }else if(this.zoom_level==1){
      clip.pixels_lenght = this.buildTimeLineLargeZoom(clip);
      this.formatOneThumbnailPerSecondClip(clip);
    }
    this.getThumnailClic(clip);
    this.clasifyClipsAdjudsPositions(clip.id);
  }
  
  // ajuste de transicion en clip local
  updateLocalClipFromSettingsTransition(data){
    for(let i=0; i<this.clics.length;i++){
      if(data.clip_id == this.clics[i].id){
        this.clics[i].json.effect_transition.type = data.transition_body;
        break;
      }
    }
  }
  // ajusta las posiciones de los clips cuando se ajusta la velocidad de alguno
  clasifyClipsAdjudsPositions(clip_id){
    let adjust_postion = false;
    let clips_to_adjust = [];
    for(let i=0; i<this.clics.length; i++){
      if(adjust_postion){
        let position = (this.clics[i-1].end - this.clics[i-1].start) + this.clics[i-1].position;
        this.clics[i].position = position;
        clips_to_adjust.push(this.clics[i]);
      }
      if(clip_id==this.clics[i].id){
        adjust_postion = true;
      }
    }
    this.updateClipSorter(clips_to_adjust);
  }
  // ajusta las transiciones y quita la ultima
  sortTransitions(){
    for(let i=0;i<this.clics.length;i++){
      if(this.clics.length == i+1){
        this.clics[i].is_last = true;
      }else{
        this.clics[i].is_last = false;
      }
    }
    this.loader=false;
  }
  //structura los paramestros de la transicion
  structureEffectTransition(clip){
    let fps = 30;
    let start_time_effect = 0;
    let middle_time_effect = 1;
    let end_time_effect = 2;
    let segment = clip.end - clip.start;
    let position_effect = clip.position + segment - 1;
    let project_name= this.project.url;

    let X1 = start_time_effect * fps + 1;
    let X2 = middle_time_effect * fps + 1;
    let X3 = end_time_effect * fps + 1;

    let Y1 = -1
    let Y2 = 1
    let Y3 = -1

    this.createEffectsTransition(position_effect, start_time_effect, end_time_effect,project_name, X1, X2, X3, Y1, Y2,Y3, clip); 
  }
  // crear transicion de efecto
  createEffectsTransition(position_parameter, start_parameter, end_param, project_param ,X1, X2, X3, Y1, Y2, Y3, clip){
    let transition = new Effect();
    transition.title = "Transition";
    transition.type = "Mask";
    transition.start = start_parameter;
    transition.end = end_param;
    transition.position = position_parameter;
    transition.layer = 1;
    transition.project = project_param;
    transition.json = {
      "brightness": {
        "Points": [
            {
                "co": {
                    "X": X1,
                    "Y": Y1
                },
                "handle_left": {
                    "X": 0.5,
                    "Y": 1
                },
                "handle_right": {
                    "X": 0.5,
                    "Y": 0
                },
                "handle_type": 0,
                "interpolation": 0
            },
            {
                "co": {
                    "X": X2,
                    "Y": Y2
                },
                "handle_left": {
                    "X": 0.5,
                    "Y": 1
                },
                "handle_right": {
                    "X": 0.5,
                    "Y": 0
                },
                "handle_type": 0,
                "interpolation": 0
            },
            {
                "co": {
                    "X": X3,
                    "Y": Y3
                },
                "handle_left": {
                    "X": 0.5,
                    "Y": 1
                },
                "handle_right": {
                    "X": 0.5,
                    "Y": 0
                },
                "handle_type": 0,
                "interpolation": 0
            }
        ]
    },
      "reader": {
          "acodec": "",
          "audio_bit_rate": 0,
          "audio_stream_index": -1,
          "audio_timebase": {
              "den": 1,
              "num": 1
          },
          "channel_layout": 4,
          "channels": 0,
          "display_ratio": {
              "den": 4,
              "num": 5
          },
          "duration": 86400.0,
          "file_size": "1658880",
          "fps": {
              "den": 1,
              "num": 30
          },
          "has_audio": false,
          "has_single_image": true,
          "has_video": true,
          "height": 576,
          "interlaced_frame": false,
          "metadata": {},
          "path": "/home/ubuntu/api/api_app/static/transitions/common/fade.svg",
          "pixel_format": -1,
          "pixel_ratio": {
              "den": 1,
              "num": 1
          },
          "sample_rate": 0,
          "top_field_first": true,
          "type": "QtImageReader",
          "vcodec": "",
          "video_bit_rate": 0,
          "video_length": "2592000",
          "video_stream_index": -1,
          "video_timebase": {
              "den": 30,
              "num": 1
          },
          "width": 720
      }
    }
    this.createTransition(transition, clip);
  }
  createTransition(transition, clip){
    this._projectsService.createEffect(transition).subscribe(
      success=>{
        let effect:any=success;
        let type = effect.json.reader.path;
        type = type.split("/")[8];
        type = type.split(".")[0];
        let effect_body = {
          type:type,
          id:effect.id
        }
        clip.json.effect_transition = effect_body;
        this.addClicToInterface(clip);
        this.updateClip(clip);
        this.sortTransitions();
      },
      error=>{
        //this._snackBar.open("Error al crear el efecto.", "Cerrar", {
        //  duration: 5000,
        //});
        Swal.fire({
          type: "error",
          text: "Error al crear el efecto."
        });
      }
    );
  }
  // llama el metodo que arma los eventos segun los clips y el previsualizador para reproducirlos
  preview(position){
    this.btnShowPreview = false;
    this.makeTimeLineIndicatorLogic(position);
    this._brokerService.initPreview(this.event_list);
  }
  // crea un evento del editor
  createEvent(clip){
    if(clip!=undefined){
      let start_position = Math.floor(clip.position);
      let event:Event = new Event();
      let minutes:any = 0;
      let seconds:any = 0;
      if(start_position>60){
        minutes = Math.floor(start_position/60);
        seconds =  start_position % 60;
        if(seconds<10){
          seconds = "0"+seconds;
        }
      }else{
        minutes = "0";
        if(start_position<10){
          seconds = "0"+start_position;
        }else{
          seconds = start_position;
        }
      }
      
      let description = "Evento";
      event.second = String(seconds);
      event.minute = String(minutes);
      event.description = description;
      event.id = uuid.v4();
      event.clip_id = clip.id;
      let json_project = this.project.json;
      if(json_project!=undefined){
        let events:any = json_project.events;
        if(events==undefined){
          let new_events = [];
          new_events.push(event);
          json_project.events = new_events;
        }else{
          let last_events = events;
          last_events.push(event);
          json_project.events = last_events;
        }
        this.project.json = json_project;
      } 
      this.updateProject();
    }
  }

  addEvent(event_param){
    const dialogRef = this.dialog.open(AddEventComponent,{
      height: '455x',
      width: '300px',
      data: { file_id:this.source_on_edit.id, event:event_param}
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result != undefined){
        let event:Event = new Event();
        event = result;
        this.updateEvent(event);
      }
    });
  }

  updateEvent(event:Event){
    if(event!=undefined){
      let json_project = this.project.json;
      if(json_project!=undefined){
        let events:any = json_project.events;
        if(events!=undefined){
          for(let i=0; i< this.events.length; i++){
            if(this.events[i].id == event.id){
              this.events[i]= event;
            }
          }
          json_project.events = events;
          this.project.json = json_project;
          this.updateProject();
        }else{
         // this._snackBar.open("Error al actualizar el evento.", "Cerrar", {
          //  duration: 5000,
          //});
          Swal.fire({
            type: "error",
            text: "Error al actualizar el evento."
          });
        }
      } 
    }
  }

  structureEvents(){
    if(this.events!=undefined){
      for(let i=0;i<this.events.length;i++){
        let time_complete = String(this.events[i].minute) + String(this.events[i].second);
        this.events[i].factor_to_order = time_complete;
      };
      this.sortEvents();
    }
  }

  sortEvents(){
    this.events.sort(function(a, b) {
      return parseInt(a.factor_to_order) - parseInt(b.factor_to_order);
    });
  }

  editEvent(id_event){
    let event:Event = new Event();
    for(let i=0;i<this.events.length;i++){
      if(this.events[i].id==id_event){
        event=  this.events[i];
      }
    }
    this.addEvent(event);
  }

  reSortEvents(){
    let events_sorter = [];
    for(let i=0; i<this.clics.length;i++){
      for(let j=0; j<this.events.length; j++){
        if(this.clics[i].id == this.events[j].clip_id){
          let start_position = Math.floor(this.clics[i].position);
          let minutes:any = 0;
          let seconds:any = 0;
          if(start_position>60){
            minutes = Math.floor(start_position/60);
            seconds =  start_position % 60;
            if(seconds<10){
              seconds = "0"+seconds;
            }
          }else{
            minutes = "0";
            if(start_position<10){
              seconds = "0"+start_position;
            }else{
              seconds = start_position;
            }
          }
          this.events[j].minute = String(minutes);
          this.events[j].second = String(seconds);
          events_sorter.push(this.events[j]);
        }
      }
    }
    this.project.json.events = events_sorter;
    this.updateProject();
  }

  listenAddClipCoverPageModules(){
    console.log("listenChangePositionModules");
    this._brokerService.getCoverPageModule().subscribe(
      data=>{
        if(data!=undefined){
          console.log(data);
          //this.loadProject();
          this.addCoverPage(data.clip_id);
        }
     });
   }

   listenResetBtnPlay(){
    console.log("listenResetBtnPlay");
    this._brokerService.getEventBtnPlay().subscribe(
      data=>{
        if(data!=undefined){
          console.log(data);
          this.btnShowPreview = true;
          //this.loadProject();
          //this.addCoverPage(data.clip_id);
        }
     });
   }


   addCoverPage(clip_id){
    let route = "clips/"+clip_id+"/";
    let request = this._projectsService.getClic(route).subscribe(
      success=>{
        let clip:any = success;
        clip = clip.body;
        clip.json.file = clip.file;
        if(clip.json.cover_page!=undefined){
          this.clics.unshift(clip);
        }
        this.reOrderClips();
        this.updateLocalClipFromSettings(clip);
      },
      error=>{
        console.log(error);
      }
    );
   }

   deleteClip(clip, type_clip){
    const dialogRef = this.dialog.open(DeleteEntityComponent,{
      height: '140px',
      width: '300px',
      data: { clip:clip, type_clip:type_clip}
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result!=undefined){
        if(result.type_clip=="is_video"){
          this.removeClipOnLocal(result);
        }else{
          this.removeAudioClipOnLocal(result);
        }
        
      }
    });
  }

  removeClipOnLocal(result){
    for(let i=0;i<this.clics.length;i++){
      if(result.id_clip_deleted==this.clics[i].id){
        this.clics.splice(i,1);
        break;
      }
    }
    this.reOrderClips();
    this.sortTransitions();
  }

  removeAudioClipOnLocal(result){
    for(let i=0;i<this.audio_clics.length;i++){
      if(result.id_clip_deleted==this.audio_clics[i].id){
        this.audio_clics.splice(i,1);
        break;
      }
    }
    this.reOrderClips();
    this.sortTransitions();
  }


  //lanza modulo de configuración de marker 
  getSourceToProject(){
    const dialogRef = this.dialog.open(AddSourceToProjectComponent,{
      height: '585x',
      width: '300px',
      data: { }
    });
    dialogRef.afterClosed().subscribe(result => {
    });
  }

  listenRefreshFiles(){
    console.log("listenRefreshFiles");
      this._brokerService.getRefreshFilesModule().subscribe(
        data=>{
          if(data!=undefined){
            this.getFiles();
          }
       });
   }

   listenRefreshEvents(){
    console.log("listenRefreshEvents");
      this._brokerService.getRefreshFilesModule().subscribe(
        data=>{
          if(data!=undefined){
            let event:Event = new Event();
            event = data;
            this.updateEvent(event);
          }
       });
   }

   loadAudioSource(audio_file_id){
    if(this.canCreate()){
      for(let i=0; i <this.audio_files.length; i++){
        if(this.audio_files[i].id==audio_file_id){
          this.cutAudioClip(this.audio_files[i]);
          break;
        }
      }
    }else{
      Swal.fire({
        type: "error",
        text: "Ya existe un audio clip agregado."
      });
    }
   }

   cutAudioClip(file){
      this.loader=true;
      this.audio_clip.project = this.project.url;
      this.audio_clip.position = this.validatePositionAudioClic();
      this.audio_clip.start = 0;
      this.audio_clip.end = this.validateEndAudioClip(file);
      this.audio_clip.layer = 3;
      this.audio_clip.json={
          speed:"1",
          real_end: this.audio_clip.end,
          is_audio:true
      };
      this.audio_clip.file = file.url;
      this.audio_clip.json.media_file = file.media;
        
      this._projectsService.createClip(this.audio_clip).subscribe(
          clip=>{
            //this._snackBar.open("Audio clip agregado", "Cerrar", {
            //  duration: 5000,
            //});
            Swal.fire({
              type: "success",
              text: "Audio clip agregado."
            });
            this.loader=false;
            this.addAudioClicToInterface(clip);
          },
          error=>{
            //this._snackBar.open("Error al cortar el audio clip", "Cerrar", {
            //  duration: 5000,
            //});
            Swal.fire({
              type: "error",
              text: "Error al cortar el audio clip."
            });
            this.loader=false;
          }
        );
   }

   validateEndAudioClip(file){
     let sum_large = 0;
     let duration_file = file.json.duration;
     if(this.audio_clics.length>0){
      for(let i=0; i<this.audio_clics.length; i++){
        let segment = this.audio_clics[i].end - this.audio_clics[i].start;
       sum_large =  sum_large + segment;
      }
      let sum_duration = sum_large;
      sum_large = sum_large + duration_file;
      
      if(sum_large>600){
        duration_file = 600 - sum_duration;
      }
     }
     return duration_file;
   }

   // valida y define la posicion del clic al crearlo
  validatePositionAudioClic(){
    let position_last_clip = 0;
    if(this.audio_clics.length > 0){
      let segment = this.audio_clics[this.audio_clics.length-1].end - this.audio_clics[this.audio_clics.length-1].start;
      position_last_clip = (this.audio_clics[this.audio_clics.length-1].position) + segment;
      position_last_clip = position_last_clip + this.factor_to_solve_transition;
    }
    return position_last_clip;
  }

  calculateEnd(file){

  }


  // un clic para los items clip
  simpleClickFunctionAudioClip(): void{
    this.timer = 0;
    this.preventSimpleClick = false;
    let delay = 200;

    this.timer = setTimeout(() => {
      if(!this.preventSimpleClick){
        console.log("simple click on clip");
      }
    }, delay);

  }

  // doble clic para los items clip
  doubleClickFunctionAudioClip(id_clip): void{
    this.preventSimpleClick = true;
    clearTimeout(this.timer);
    //whatever you want with double click go here
    //this.settingsClip(id_clip);
    //if(this.canCreate()==false){
    this.configAudioClic(id_clip);
    //}
  }

  canCreate(){
    let valid = false;
    if(this.audio_clics.length==0){
      valid = true;
    }
    return valid;
  }

  //lanza modulo de configuración de marker 
  configAudioClic(audio_clip_id){
    const dialogRef = this.dialog.open(AdjusmentAudioClipComponent,{
      height: '585x',
      width: '300px',
      data: { audio_clip_id:audio_clip_id }
    });
    dialogRef.afterClosed().subscribe(result => {

    });
  }

  // agregar el clic al array para que sea renderizado en la interfaz de usuario
  addAudioClicToInterface(clip){
    clip.json.file = clip.file;
    if(this.zoom_level==3){
      clip.pixels_lenght = this.buildTimeLineShotZoom(clip);
    }else if(this.zoom_level==2){
      clip.pixels_lenght = this.buildTimeLineMediumZoom(clip);
    }else if(this.zoom_level==1){
      clip.pixels_lenght = this.buildTimeLineLargeZoom(clip);
    }
    this.audio_clics.push(clip);
  }

  exportVideo(){
    this.loader = true;
    this.export_video = true;
    if(this.validateExport()){
      this.makeAudioTrackLarge();
    }
  }

  makeAudioTrackLarge(){
    if(this.audio_clics.length > 0){
      let dur = (this.clics[this.clics.length-1].end - this.clics[this.clics.length-1].start);
      let large_video = this.clics[this.clics.length-1].position + dur;
      let audio_clip = this.audio_clics[0];
      this.last_duration_audio_clip = audio_clip.end;
      this.audio_clics[0].end = large_video;
      this.updateClipAudio(this.audio_clics[0]);
    }else{
      this.executeExport();
    }
  }

  // actauliza el clip en la API
  updateClipAudio(clip){
    this._projectsService.updateClic(clip, clip.id).subscribe(
      success=>{
        this.executeExport();
      },
      error=>{
       // this._snackBar.open("Error al actualizar el clic.", "Cerrar", {
        //  duration: 5000,
        //});
        Swal.fire({
          type: "error",
          text: "Error al actualizar el clic."
        });
      }
    );
  }

  executeExport(){
    let name_file = uuid.v4();
        let export_body:Exports = new Exports(this.project.url, name_file);
        this._projectsService.exportVideo(export_body, this.project.id).subscribe(
          success=>{
            //this.returnAudioLarge();
           // this._snackBar.open("Video promocional en exportación.", "Cerrar", {
            //  duration: 5000,
            //});
            Swal.fire({
              type: "success",
              text: "Video promocional en exportación. Al finalizar, este se podrá observar desde el portal en la sección videos"
            });
            this.loader = false;
            this._router.navigate(['projects'])
          },
          error=>{
            this.loader = true;
            console.log(error);
            console.log( "Si aqui");
            //this._snackBar.open("Error al exportar el video.", "Cerrar", {
            //  duration: 5000,
            //});
            Swal.fire({
              type: "error",
              text: "Error al exportar el video."
            });
           // this.export_video = false;
            this.loader = false;
          }
        );
  }

  returnAudioLarge(){
    if(this.audio_clics.length > 0){
      this.audio_clics[0].end = this.last_duration_audio_clip;
      this.updateClip(this.audio_clics[0]);
    }
  }

  validateExport(){
    let validate = false;
    let has_cover_page = true;
    if(this.clics.length>0){
      for(let i = 0; i<this.clics.length; i++){
        if(this.clics[i].json.is_market_video!=undefined && this.clics[i].json.is_market_video==true){
          has_cover_page= true;
          break;
        }
      }
      if(has_cover_page){
        validate = true;
      }else{
        //this._snackBar.open("Debes configurar la portada antes de exportar.", "Cerrar", {
        //  duration: 5000,
        //});
        this.loader = false;
        
        Swal.fire({
          type: "warning",
          text: "Debes configurar la portada antes de exportar."
        });
      }
    }else{
     // this._snackBar.open("Debes configurar clips de video antes de exportar.", "Cerrar", {
     //   duration: 5000,
     // });
     this.loader = false;
     
      Swal.fire({
        type: "warning",
        text: "Debes configurar clips de video antes de exportar."
      });
    }
    return validate;
  }

  generateWhiteMark(){

  }

  /*
  makeWhiteMark(is_to_marker_param){
    if(this.validateClic()){
      let clip_water_mark:Clic = new Clic();
      clip_water_mark.project = this.project.url;
      clip_water_mark.position = 0
      clip_water_mark.start = parseInt(this.floor_range_indicator_seconds);
      clip_water_mark.end = parseInt(this.ceil_range_indicator_seconds);
      clip_water_mark.layer = 4;
      clip_water_mark.json={
        is_water_mark:true,
        media_file:this.water_mark_file.media
      };
      clip_water_mark.file = this.water_mark_file.url
        this._projectsService.createClip(clip_water_mark).subscribe(
          clip=>{
            this._snackBar.open("Clip cortado", "Cerrar", {
              duration: 5000,
            });

          },
          error=>{
            this._snackBar.open("Error al crear la marca de agua.", "Cerrar", {
              duration: 5000,
            });
          }
        );
    }
  }
  */
  //------------------------Metodos para movil
  showPreview(){
    this.videoplayerClips = false;
    this.videoplayerpreview = true;
  }

  cut(){
    this.videoplayerClips = true;
    this.videoplayerpreview = false;
  }
  
  tabChanged(tabChangeEvent): void {
    if(tabChangeEvent.target.innerText == "VIDEO"){
      if(this.showVideos){
        this.showVideos = false;
        this.showAudios = false;
        this.showEvents = false;
      }else {
        this.showVideos = true;
        this.showAudios = false;
        this.showEvents = false;
      }
    }
    if(tabChangeEvent.target.innerText == "AUDIO"){
      if(this.showAudios){
        this.showVideos = false;
        this.showAudios = false;
        this.showEvents = false;
      }else {
        this.showAudios = true;
        this.showVideos = false;
        this.showEvents = false;
      }
    }
    if(tabChangeEvent.target.innerText == "EVENTO"){
      if(this.showEvents){
        this.showVideos = false;
        this.showAudios = false;
        this.showEvents = false;
      }else {
        this.showEvents = true;
        this.showVideos = false;
        this.showAudios = false;
      }
    }
  }
  getCoverToMobile(){
    this._brokerService.initCoverPageMobile(1);
  }

  addCoverPageMobile(){
    const dialogRef = this.dialog.open(CoverPageComponent,{
      height: '455x',
      width: '300px',
      data: { }
    });
    dialogRef.afterClosed().subscribe(result => {
      this.sendClipTo(result);
    });
  }

  sendClipTo(result){
    if(result!=undefined){
      if(result.status==1){
        this._brokerService.initCoverPage(result);
      }
    }
  }
  // ir a ayuda
  goToHelp(){
    this._router.navigate(["./help"]);
  }

  clicRangeInput(){

    if(this.canCut && this.canCut_2){
      this.adjusdTimeCutter();
    }else{
        Swal.fire({
          type: "warning",
          text: "Debe seleccionar el rango del videoclip"
        });
    }
  }

  // abrir modal para editar tiempo de recorte de clip
  adjusdTimeCutter(){
    const dialogRef = this.dialog.open(AdjustTimeCutterComponent,{
      height: '360px',
      width: '300px',
      data: { init_time:this.floor_range_indicator, end_time:this.ceil_range_indicator, total_time:this.initReproductorTime}
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result!=undefined){
        let init_time = result.init_time;
        let end_time = result.end_time;
        this.adjustClipByTimmeCutter(init_time, end_time);
      }
    });
  }

  adjustClipByTimmeCutter(init_time, end_time){
    this.floor_range_indicator = init_time;
    this.ceil_range_indicator = end_time;

    this.minValueRange = this.HMSToSeconds(this.floor_range_indicator);
    this.minValue = this.HMSToSeconds(this.floor_range_indicator);
    this.maxValueRange = this.HMSToSeconds(this.ceil_range_indicator);
    this.maxValue = this.HMSToSeconds(this.ceil_range_indicator);

    setTimeout(() => {
      this.disabled_options=false;
    }, 1000);
    
    /*
    let options_range_video_editor: Options = Object.assign({}, this.options_range_video_editor);
    options_range_video_editor.floor = this.HMSToSeconds(this.floor_range_indicator);
    options_range_video_editor.ceil = this.HMSToSeconds(this.ceil_range_indicator);
    this.options_range_video_editor = options_range_video_editor;

    let options_cut_video_editor: Options = Object.assign({}, this.options_cut_video_editor);
    options_cut_video_editor.floor = this.HMSToSeconds(this.floor_range_indicator);
    options_cut_video_editor.ceil = this.HMSToSeconds(this.ceil_range_indicator);
    this.options_cut_video_editor = options_cut_video_editor;
    */

  }
}


