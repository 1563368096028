// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

export const GLOBAL={
    
  //apiMVSVideo:'http://52.6.124.58/',
  apiMVSVideo:'https://apivideo.mvstars.com/',
  
  /*
  apiES2:'http://52.23.183.249:8000/api/v1/',
  client_id:'6a4koHDpJnjMu9xaa1bTnWU6J6mwu7DVGhaLsC9L',
  secret_id:'dlW1rLG88ZTygY8lNR9anuBgpiEZQJtmjjfxc0DjiUIc44EJXYwuXgDdiF4Bzvt08rBZRC6QUKDaYDbvWnSkZdJUDOUqcpgrILV0bX4fxjpjCmOwxAnuVUJPB2v77xUD',
  grant_type:'password'
  */
}
