import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { Clic, Effect } from '../../../models/editor';
import { ProjectsService } from '../../../services/projects/projects.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Options } from 'ng5-slider';
import { calcPossibleSecurityContexts } from '@angular/compiler/src/template_parser/binding_parser';
import { CdkDragEnd } from "@angular/cdk/drag-drop";
import { VgAPI,VgMedia } from 'videogular2/compiled/core';
import {AuthService} from '../../../services/auth/auth.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-get-marker',
  templateUrl: './get-marker.component.html',
  styleUrls: ['./get-marker.component.css', './styled-slider.component.scss']
})

export class GetMarkerComponent implements OnInit {
  private clip:Clic;
  private clip_to_marker:Clic;
  private file_image:string;
  private fps:number;
  private start_clip_frame:number;
  private end_clip_frame:number;

  private value: number;//frame selecionado
  private value_2: number;
  private ceil_slider:number=1;

  private fps_selected:number;
  private options_get_thumnail:Options= {
    floor: 1,
    ceil: this.ceil_slider,
    step: 1,
    disabled: false
  };
  private options_get_zoom:Options= {
    floor: 1,
    ceil: 100,
    step: 1,
    disabled: false
  };

  private second_array:any[];
  private cuadro_array:any[];
  private duration_clip:number;

  private current_second:number;
  private current_fps:number;

  private total_fps:number;

  private second_status:boolean;
  private frame_status:boolean;
  private slider_status:boolean;
  //private on_choose_frame:boolean;

  //@ViewChild("item")item: ElementRef;

  //private initialPosition = {};
  //private position = {};
  //private offset = {};
  //private size = {};

  private initialPosition = { x: 0, y: 0 };
  private position = { ...this.initialPosition };
  private offset = { x: 0, y: 0 };
  private size = { x: "50px", y: "50px" };

  private marker_config:any;

  private api:VgAPI;

  private video_to_get_marker:string;

  private step:number;

  private is_playing:boolean;

  private interval_control_end:any;

  private has_fps:boolean;

  private current_time:number;

  private fps_min:number;

  private fps_max:number;

  private id_clip_marker:number;

  private url_file_marker:string;

  private media_file_marker:string;

  private location_marker:any;

  private is_loading_thumbnail:boolean;

  private width_container_marker:number;

  private height_container_marker:number;

  private width_video:number;

  private height_video:number;

  private array_scale_reference:any[];

  private size_marker:number;

  private freeze:number;

  private loader_1:boolean;

  private loader_2:boolean;

  public fullScreen:boolean; // validar si la pantalla esta full screen

  constructor(
    private _snackBar:MatSnackBar,
    private _projectsService:ProjectsService,
    public dialogRef: MatDialogRef<GetMarkerComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _authService:AuthService
  ) { 
    this.clip=new Clic();
    this.file_image="";
    //this.on_choose_frame = true;
    this.value_2=50;
    this.step=1;
    this.is_playing = false;
    this.has_fps = true;
    //this.current_time = 0;
    this.id_clip_marker=0;
    this.location_marker={
      x1:0,
      x2:0,
      y1:0,
      y2:0
    }
    this.clip_to_marker = new Clic();
    this.is_loading_thumbnail=false;
    this.width_container_marker = 257;
    this.height_container_marker = 145;
    this.height_video = 1080;
    this.width_video = 1920;
    this.array_scale_reference = [];
    this.freeze=2;
    this.loader_1=true;
    this.loader_2=false;
    this.fullScreen=false;
  }

  ngOnInit() {
    this.getFiles();
    this.getClipToFreeze();
    
  }

  makeScaleReference(){
    let reference_sight = (this.height_container_marker)/100;
    let reference_screen = (this.height_video)/100;
    for(let i=0; i<100; i++){
      let object = {
        value: (i+1),
        reference_sight: (i+1)*reference_sight, 
        reference_screen: (i+1)*reference_screen
      }
      this.array_scale_reference.push(object);
    }
    console.log(this.array_scale_reference);
  }

   // metodo que se carga al estar listo el reproductor para realizar recortes
  onPlayerReady(api:VgAPI) {
    this.api = api;
    this.api.fsAPI.onChangeFullscreen.subscribe(
        event => {
            this.onChangeFullscreen(event);
        }
    );
  }
  
  onChangeFullscreen(event){
    this.fullScreen = event;
  }

  getClipToFreeze(){
    this._projectsService.getClicById(this.data.clip_id).subscribe(
      success=>{
        let clip:any = success.body;
        this.clip = clip;
        this.fps = Math.round(this.clip.json.reader.fps.num/this.clip.json.reader.fps.den);
        this.start_clip_frame = this.clip.start * this.fps;
        this.end_clip_frame = this.clip.end * this.fps;
        this.duration_clip = this.clip.end - this.clip.start;
        this.fps_min = this.clip.start * this.fps;
        this.fps_max = this.clip.end * this.fps;
        this.total_fps = this.duration_clip * this.fps;
        this.marker_config = this.clip.json.marker_setting;
        //this.makesArray();
        this.setInitValues();
      },
      error=>{
        this._snackBar.open("Error al cargar el clip.", "Cerrar", {
          duration: 5000,
        });
      }
    );
  }

  getClipToMarker(id_clip_marker){
    this._projectsService.getClicById(id_clip_marker).subscribe(
      success=>{
        let clip:any = success.body;
        this.clip_to_marker = clip;
      },
      error=>{
        this._snackBar.open("Error al cargar el clip.", "Cerrar", {
          duration: 5000,
        });
      }
    );
  }

  setMedia(media:String){
    let media_file = media.replace("media","signed");  
    let token=this._authService.getStoragedToken(); 
    this.video_to_get_marker = media_file+"?Authorization="+token;
  }

  startMedia(){
    (<VgMedia>this.api.getMasterMedia()).loadMedia();
    this.api.getDefaultMedia().subscriptions.canPlay.subscribe(() => {
      if(this.is_playing==false){
        this.setStartSecond(this.marker_config.current_time);
      }
    });
  }

  resStartMedia(){
    /*
    (<VgMedia>this.api.getMasterMedia()).loadMedia();
    this.api.getDefaultMedia().subscriptions.canPlay.subscribe(() => {
      if(this.is_playing==false){
        this.is_playing = true;
        */
    this.setStartSecond(this.clip.start);
        /*
      }
    });*/
  }

  setStartSecond(start_second){
    this.is_playing = true;
    this.api.seekTime(start_second);
    this.api.pause();
    setTimeout(() => {
      this.loader_1=false;
    }, 1000);
  }

  controlEndClip(){
    this.interval_control_end = setInterval(() => {
      console.log("Current time: "+ this.api.currentTime);
      console.log("End time: "+this.clip.end);
      if(this.api.currentTime==undefined || this.clip.end==undefined){
        clearInterval(this.interval_control_end);
      }
      if(this.api.currentTime>=this.clip.end){
        clearInterval(this.interval_control_end);
        this.reStartClip();
      }
    }, 1);
  }

  pauseClip(){
    console.log("pauseClip");
    this.api.pause();
    let current_time = this.api.currentTime;
    console.log("Selected current time:" +  this.current_time)
    clearInterval(this.interval_control_end);
    this.setCurrentFPS(current_time);
  }

  setCurrentFPS(second_param){
    let time_fps = second_param;
    this.value = Math.round(time_fps * this.fps);
  }

  playClip(){
    this.has_fps=true;
    this.api.play();
    this.controlEndClip();
    
  }

  reStartClip(){
    this.is_playing=false;
    this.resStartMedia();
  }
  
  makesArray(){
    for(let i=0 ; i<this.duration_clip ; i++){
      this.second_array.push(
        {
          value: i+1,
          title: i+1
        }
      );
    }
    for(let i=0 ; i<this.fps ; i++){
      this.cuadro_array.push(
        {
          value: i+1,
          title: i+1
        }
      );
    }
    console.log(this.second_array);
    console.log(this.cuadro_array);
  }

  toFirsStep(){
    console.log("toFirsStep");
    this.step = 1;
    setTimeout(() => {
      //debugger;
      console.log("Selected current time:" +  this.current_time)
      this.api.seekTime(this.current_time);
      this.pauseClip();
    }, 1000); 
  }

  backToFirsStep(){
    console.log("toFirsStep");
    this.step = 1;
    
    setTimeout(() => {
      //debugger;
      console.log("Selected current time:" +  this.current_time)
      this.api.seekTime(this.current_time);
      this.pauseClip();
    }, 100); 
  }

  toSecondStep(){
    this.current_time = this.api.currentTime;
    this.getThumnailClic(this.clip, this.value);
    setTimeout(() => {
      this.step = 2;
      this.setValuesSecondStep();
    }, 1000);
  }

  backToSecondStep(){
    //this.getThumnailClic(this.clip, this.value);
    //setTimeout(() => {
      this.step = 2;
      //this.setValuesSecondStep();
    //}, 1000);
  }

  toTreeStep(){
    this.step = 3;
  }

  getThumnailClic(clic, thumnails_position){
      let file = clic.json.file.split("/");
      let position_thumnail = parseInt(thumnails_position);
      let request = this._projectsService.getThumnailClipToMarker(file[4],position_thumnail).subscribe(
        success=>{
          let image = this.createImageFromBlob(success, clic);
        },error=>{
          this._snackBar.open("Error al cargar el thumnail.", "Cerrar", {
            duration: 5000,
          });       
        }
      );
  }

  // Genera la imagen por blob
  createImageFromBlob(image: Blob, clic) {
    let reader = new FileReader();
    let image_result:any;
    reader.addEventListener("load", () => {
      image_result = reader.result;
      this.file_image = image_result;
      this.is_loading_thumbnail=false;
    }, false);
    if (image) {
       reader.readAsDataURL(image);
    }
    return image_result;
  }

  setInitValues(){
    this.initialPosition = { x: this.marker_config.position_marker_x, y: this.marker_config.position_marker_y };
    this.position = { ...this.initialPosition };
    this.offset = { x: 0, y: 0 };

    this.makeScaleReference();
    
    if(this.marker_config.value_slide_zoom==0){
      this.value_2 = 50
    }else{
      this.value_2 = this.marker_config.value_slide_zoom;
    }

    if(this.marker_config.zoom_marker==0){
      this.size_marker = this.array_scale_reference[49].reference_sight;
    }else{
      this.size_marker = this.marker_config.zoom_marker;
    }
    
    this.size.x = String(this.size_marker);
    this.size.y = String(this.size_marker);

    if(this.marker_config.fps_position==0){
      let fps = this.clip.json.reader.fps.num / this.clip.json.reader.fps.den;
      this.value = this.clip.start * fps;
    }else{
      this.value = this.marker_config.fps_position;
    }

    this.id_clip_marker = this.marker_config.id_clip_marker;

    this.value_2=50;

    if(this.id_clip_marker > 0){
      this.getClipToMarker(this.id_clip_marker);
    }
    this.setMedia(this.clip.json.media_file);
    this.startMedia();
  }

  setValuesSecondStep(){
    //this.value = this.fps_selected;
  }

  nextFrame(){
    this.is_loading_thumbnail=true;
    if(this.value<this.fps_max){
      this.value = this.value + 1; 
      let fps_on_second = 1 / this.fps;
      this.current_time = this.current_time + fps_on_second;
      //this.fps_selected = this.value;
      this.getThumnailClic(this.clip, this.value);
    }else{
      this._snackBar.open("Llego al limite maximo del clip.", "Cerrar", {
        duration: 5000,
      }); 
    }
  }

  forwardFrame(){
    this.is_loading_thumbnail=true;
    if(this.value>this.fps_min){
      this.value = this.value - 1;
      let fps_on_second = 1 / this.fps;
      this.current_time = this.current_time - fps_on_second;
      //this.fps_selected = this.value;
      this.getThumnailClic(this.clip, this.value);
    }else{
      this._snackBar.open("Llego al limite minimo del clip.", "Cerrar", {
        duration: 5000,
      }); 
    }
    
  }
  
  validateClipToMarker(){
    this.loader_2=true;
    if(this.id_clip_marker==0){
      this.createdClipToMarker();
    }else{
      this.updateClipToMarker(this.clip_to_marker);
    }
  }

  // actauliza el clip en la API
  updateClipToMarker(clip){
    clip.position = this.getClipMarkerPosition();
    clip.start = 0;
    clip.end = 2;
    let location = this.calcMarkerLocation(clip);
    let zoom = this.calcMarkerScale();
    let json_body = {
      scale_x:zoom.scale_x,
      scale_y:zoom.scale_y,
      location_x:location.location_x,
      location_y:location.location_y,
      is_market_video:true,
      media_file:this.media_file_marker
    }
    clip.json = json_body;
    this._projectsService.updateClic(clip, clip.id).subscribe(
      success=>{
        /*
        this._snackBar.open("El marker ha sido actualizado.", "Cerrar", {
          duration: 5000,
        });*/
        this.updateClipFreeze();
      },
      error=>{
        this._snackBar.open("Error al actualizar el clic.", "Cerrar", {
          duration: 5000,
        });
      }
    );
  }

  getFiles(){
    this._projectsService.getFiles(this.data.project.id).subscribe(
      success=>{
        let success_body:any = success; 
        for(let i=0;i<success_body.body.results.length;i++){
            let media = success_body.body.results[i].media;
            let name = media.split("/")[7];
            if(name=="mira.mov"){
              this.url_file_marker = success_body.body.results[i].url;
              this.media_file_marker = success_body.body.results[i].media;
            }
        }
      },
      error=>{
        this._snackBar.open("Error al cargar las fuentes", "Cerrar", {
          duration: 5000,
        });
      }
    );
  }

  
  createdClipToMarker(){
    let clip:Clic = new Clic();
    clip.project = this.clip.project;
    clip.position = this.getClipMarkerPosition();
    clip.start = 0;
    clip.end = 2;
    clip.file = this.url_file_marker;
    let location = this.calcMarkerLocation(clip);
    let zoom = this.calcMarkerScale();
    let json_body = {
      scale_x:zoom.scale_x,
      scale_y:zoom.scale_y,
      location_x:location.location_x,
      location_y:location.location_y,
      is_market_video:true,
      media_file:this.media_file_marker
    }
    clip.json = json_body;
    clip.layer = 2;
        this._projectsService.createClip(clip).subscribe(
          clip=>{
            let clip_body:any = clip;
            this.id_clip_marker = clip_body.id;
            this._snackBar.open("Clip cortado", "Cerrar", {
              duration: 5000,
            });
            this.updateClipFreeze();
          },
          error=>{
            this._snackBar.open("Error al cortar el clip", "Cerrar", {
              duration: 5000,
            });
          }
        );
  }

  closeComponent(clip){
    this.dialogRef.close({data:clip});
  }
  
  
  getClipMarkerPosition(){
    let relative_time = this.current_time - this.clip.start;
    let second = relative_time + this.clip.position;
    return second;
  }

  userChangeZoom(event){
    let size = this.defineZoomOnLocal(event.value);
    this.size_marker = size;
    this.changeZoomMira(size);
    this.calcMarkerScale();
  }

  defineZoomOnLocal(size){
    let value_real = 0;
    for(let i=0; i<this.array_scale_reference.length; i++){
      if(this.array_scale_reference[i].value==size){
        value_real = this.array_scale_reference[i].reference_sight;
      }
    }
    return value_real;
  }

  changeZoomMira(value){
    let value_string = value; 
    this.size.x = value_string;
    this.size.y = value_string; 
    this.calcCordinatesMarker();
  }

  updateClipFreeze(){
    this.structureClipToUpdate();
  }

  structureClipToUpdate(){
    this.clip.json.time = this.freezeConfig(this.clip);
    this.clip.json.marker_setting = this.calcMarkerPositionConfig();
    this.updateClip(this.clip);
  }

  calcMarkerPositionConfig(){
    let body_marker_config = {
      fps_position:this.value, // fps seleccionado
      zoom_marker:this.size.x, // 
      value_slide_zoom:this.value_2, // 
      position_marker_x:this.position.x, //
      position_marker_y:this.position.y, //
      current_time:this.current_time, // 
      id_clip_marker:this.id_clip_marker,
      height_container_marker: this.height_container_marker,
      width_container_marker: this.width_container_marker
    }
    return body_marker_config;
  }

  // actauliza el clip en la API
  updateClip(clip){
    this._projectsService.updateClic(clip, clip.id).subscribe(
      success=>{
        let success_body:any = success;
        this._snackBar.open("El marker ha sido actualizado.", "Cerrar", {
          duration: 5000,
        });
        this.loader_2=false;
        this.closeComponent(success_body);
      },
      error=>{
        this._snackBar.open("Error al actualizar el clic.", "Cerrar", {
          duration: 5000,
        });
      }
    );
  }
  

  freezeConfig(clip){
    let start = clip.start;
    let end_clip = parseInt(clip.json.real_end);
    let fps = this.fps;
    let Freeze = this.freeze;
    let FP = this.value;

    let dur = (end_clip - start) + Freeze;
    let NEnd = start + dur;

    let X1 = start * fps + 1;
    let Y1 = X1;

    let X2 = FP + 1; 
    let Y2 = X2;

    let X3 = FP + (Freeze*fps) + 1;
    let Y3 = Y2;

    let X4 = NEnd * fps + 1;
    let Y4 = end_clip * fps + 1;

    let points = [];
    let first_object={
      "interpolation": 1,
      "co": {
          "X": X1,
          "Y":Y1
      }
    }
    let secod_object={
      "interpolation": 1,
      "co": {
          "X": X2,
          "Y": Y2
      }
    }
    let third_object={
      "interpolation": 1,
      "co": {
          "X": X3,
          "Y": Y3
      }
    }
    let four_object={
      "interpolation": 1,
      "co": {
          "X": X4,
          "Y": Y4
      }
    }
    points.push(first_object);
    points.push(secod_object);
    points.push(third_object);
    points.push(four_object);

    let body_freeze = {
      Points:points
    }

    return body_freeze;


  }

  dragEnd(event){
    this.offset = { ...(<any>event.source._dragRef)._passiveTransform };

    console.log("X:"+<any>event.source._dragRef._pointerDirectionDelta.x);
    console.log("Y:"+<any>event.source._dragRef._pointerDirectionDelta.y);

    let event_body:any = event.source._dragRef._previewRect;

    event.source._dragRef._previewRect.height = this.size.y;
    event.source._dragRef._previewRect.width = this.size.x;
    
    this.position.x = this.initialPosition.x + this.offset.x;
    this.position.y = this.initialPosition.y + this.offset.y;

    this.calcCordinatesMarker();
    console.log(this.position, this.initialPosition, this.offset);
  }

  calcCordinatesMarker(){
    console.log(this.value_2);
    let marker_size = this.size_marker;
    let marker_radio = marker_size/2;
    let distance_x = this.position.x + marker_radio;
    let distance_y = this.position.y + marker_radio;
    let total_x = this.width_container_marker;
    let quadrant_x = total_x/2;
    let total_y = this.height_container_marker;
    let quadrant_y = total_y/2;
    let cordinate_x:any = (distance_x/quadrant_x) - 1;
    cordinate_x = cordinate_x/2;
    let cordinate_y:any = (distance_y/quadrant_y) - 1;
    cordinate_y = cordinate_y/2;

    /*
    if(distance_x < total_x){
      let porcentual_x = (distance_x*100)/quadrant_x;
      cordinate_x = 100 - porcentual_x;
      cordinate_x = (cordinate_x * -1) / 100;
    }else if(distance_x==total_x){
      cordinate_x = 0;
    }else if(distance_x>total_x){
      distance_x = distance_x - total_x;
      cordinate_x = (distance_x*100)/quadrant_x;
      cordinate_x = cordinate_x/100;
    }

    let cordinate_y:any = 0;
    if(distance_y<total_y){
      let porcentual_y = (distance_y*100)/quadrant_y;
      cordinate_y = 100 - porcentual_y;
      cordinate_y = cordinate_y/100;
    }else if(distance_y==total_y){
      cordinate_y = 0;
    }else if(distance_y>total_y){
      distance_y = distance_y - total_y;
      cordinate_y = (distance_y*100)/quadrant_y;
      cordinate_y = (cordinate_y * -1)/100;
    }
    */

    this.location_marker={
      x1:cordinate_x,
      x2:cordinate_x,
      y1:cordinate_y,
      y2:cordinate_y
    }
    //let width_container_marker=257;
    //let height_container_marker=145;
  }

  calcMarkerLocation(clip){
    let loc_x_X1 = clip.start * this.fps + 1; 
    let loc_x_Y1 = this.location_marker.x1;

    let loc_x_X2 = clip.end * this.fps + 1; 
    let loc_x_Y2 = this.location_marker.x1;

    let loc_y_X1 = clip.start * this.fps + 1; 
    let loc_y_Y1 = this.location_marker.y1;

    let loc_y_X2 = clip.end * this.fps + 1; 
    let loc_y_Y2 = this.location_marker.y2;

    let points_loc_x = [];
    let start_object_loc_x={
      "interpolation": 1,
      "co": {
        "X": loc_x_X1,
        "Y": loc_x_Y1
      }
    }
    let end_object_loc_x={
      "interpolation": 1,
      "co": {
        "X": loc_x_X2,
        "Y": loc_x_Y2
      }
    }
    points_loc_x.push(start_object_loc_x);
    points_loc_x.push(end_object_loc_x);

    let points_loc_y = [];
    let start_object_loc_y={
      "interpolation": 1,
      "co": {
        "X": loc_y_X1,
        "Y": loc_y_Y1
      }
    }
    let end_object_loc_y={
      "interpolation": 1,
      "co": {
        "X": loc_y_X2,
        "Y": loc_y_Y2
      },
    }
    points_loc_y.push(start_object_loc_y);
    points_loc_y.push(end_object_loc_y);

    let body_json_marker = {
      "location_x":{
      "Points":points_loc_x
      },
      "location_y":{
        "Points":points_loc_y
      }
    }

    return body_json_marker
  }

  calcMarkerScale(){
    let cent_percent_minor = (this.size_marker/this.height_container_marker)*100;
    
    let fps = this.fps;
    let Freeze = this.freeze;
    let Z = cent_percent_minor;

    let FP = this.current_time;

    let mid = FP + (Freeze / 2);

    let X1 = 1
    let X2 = 61

    let Y1 = Z/100;
    let Y2 = Z/100;

    let poinst_scale_x = [];
    
    let start_object_scale_x ={
      "interpolation": 1,
      "co": {
        "X": X1,
        "Y": Y1
      }
    };

    let middle_object_scale_x ={
      "interpolation": 1,
      "co": {
        "X": X2,
        "Y": Y2
      },
    };

    /*

    let end_object_scale_x ={
      "interpolation": 1,
      "co": {
        "X": X3,
        "Y": Y3
      },
    };
    */

    poinst_scale_x.push(start_object_scale_x);
    poinst_scale_x.push(middle_object_scale_x);
    //poinst_scale_x.push(end_object_scale_x);

    let poinst_scale_y = [];

    let start_object_scale_y ={
      "interpolation": 1,
      "co": {
        "X": X1,
        "Y": Y1
      }
    };

    let middle_object_scale_y ={
      "interpolation": 1,
      "co": {
        "X": X2,
        "Y": Y2
      },
    };

    /*

    let end_object_scale_y ={
      "interpolation": 1,
      "co": {
        "X": X3,
        "Y": Y3
      },
    };
    */
    
    poinst_scale_y.push(start_object_scale_y);
    poinst_scale_y.push(middle_object_scale_y);
    //poinst_scale_y.push(end_object_scale_y);

    let body_json_marker_zoom = {
      "scale_x":{
        "Points":poinst_scale_x
      },
      "scale_y":{
        "Points":poinst_scale_y
      }
    }

    /*
    "scale_x": {
      "Points": [
           {
              "co": {
                   "X": 2101.0,
                   "Y": 1.0
              },
              "interpolation": 0
          },
          {
              "co": {
                  "X": 2131.0,
                  "Y": 1.05
              },
              "interpolation": 0
          },
          {
              "co": {
                  "X": 2161.0,
                  "Y": 1.0
               },
               "interpolation": 0
           }
       ]
  },
  "scale_y": {
      "Points": [
          {
              "co": {
                  "X": 2101.0,
                  "Y": 1.0
              },
              "interpolation": 0
          },
          {
             "co": {
                  "X": 2131.0,
                  "Y": 1.05
              },
              "interpolation": 0
          },
          {
              "co": {
                  "X": 2161.0,
                  "Y": 1.0
              },
              "interpolation": 0
          }
      ]
  }
  */

    return body_json_marker_zoom;

  }

/*
  disabledInputs(): void {
    this.second_status = true;
    this.frame_status = true;
    this.slider_status = true;
    this.options_get_thumnail = Object.assign({}, this.options_get_thumnail, {disabled: this.slider_status});
  }

  enabledInputs(){
    this.second_status = false;
    this.frame_status = false;
    this.slider_status = false;
    this.options_get_thumnail = Object.assign({}, this.options_get_thumnail, {disabled: this.slider_status});
  }

  //cambia el slider de zoom
  userChangeEnd(event){
    this.calcSecondAndFrame(event.value);
  }

  changeSecond(event){
    this.calcPossibleSecurityContexts(this.current_second, this.current_fps);
  }

  changeFrame(event){
    this.calcPossibleSecurityContexts(this.current_second, this.current_fps);
  }

  calcSecondAndFrame(value){
   let second = 0;
   let frames = 0;
   while(frames < value){
    second = second + 1; // incrementando los segundos
    frames = second * this.fps; // multiplicando de a rango de frames
   }
   frames = frames - this.fps; // para bajar al frame 0 del segundo respectivo
   let position_frame_in_the_second = value - frames;
   this.current_second = second;
   this.current_fps = position_frame_in_the_second;
   this.getThumnailClic(this.clip, value);
  }

  calcPossibleSecurityContexts(second, frame){
    let frames_second = second * this.fps;
    frames_second = frames_second - this.fps;
    this.value = frames_second + frame;
    this.getThumnailClic(this.clip, this.value);
  }
*/

}
