// clase para manejar los projectos de los usuarios
export class Project{
    public id:number;
    public width:number;
    public height:number;
    public fps_num:number;
    public fps_den:number;
    public sample_rate:number;
    public channels:number;
    public channel_layout:number;
    public json:any;
    public url:string;
    public files:any[];
    public clips:any[];
    public effects:any[];
    public exports: any[];
    public actions: any[];
    public date_created:Date;
    public date_updated:Date;
    public thumnails:any[];
    constructor(public name:string){
        this.width=1920;
        this.height=1080;
        this.fps_num=30;
        this.fps_den=1;
        this.sample_rate=44100;
        this.channels=2;
        this.channel_layout=3;
        this.json={
            description:"",
            large_video:"10"
        }
    }
}

// clase para manejar el objeto de registro
export class Registered{
    public apellido:string;
    public estatura:number;
    public fuentes:any;
    public nombre:string;
    public peso:number;
    public token:string;
    public posicion:string;
    public cumple:string;
    constructor(){}
}



            