import { Component, OnInit, Input, HostBinding, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { Options } from 'ng5-slider';
import { BrokerService } from '../../../services/broker/broker.service';
import { VgAPI,VgMedia } from 'videogular2/compiled/core';
import {
  trigger,
  state,
  style,
  animate,
  transition
} from '@angular/animations';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CoverPageComponent } from '../cover-page/cover-page.component';
import {AuthService} from '../../../services/auth/auth.service';
import { Clic } from 'src/app/models/editor';
import { Registered, Project} from '../../../models/project';
import { ProjectsService } from '../../../services/projects/projects.service';
import { MediaMatcher } from '@angular/cdk/layout';
import Swal from 'sweetalert2';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-preview-video',
  templateUrl: './preview-video.component.html',
  styleUrls: ['./preview-video.component.css'],
  animations: [
    trigger('openClose', [
      state('open', style({
        opacity: 1,
        backgroundColor: '#000',
        marginLeft: 0
      })),
      state('closed', style({
        opacity: 0,
        marginLeft: 100
        //backgroundColor: 'green'
      })),
      transition('open => closed', [
        animate('1s')
      ]),
      transition('closed => open', [
        animate('1s')
      ]),
    ])
  ],
})
export class PreviewVideoComponent implements OnInit, OnDestroy {

  private api:VgAPI;
  private api2:VgAPI;
  private preview_video:string;
  private preview_video2:string;
  private current_event_first:any;
  private current_event_second:any;
  private current_interval;
  private events:any[];
  private events_a:any[];
  private events_b:any[];
  private saturation:number;
  private saturation2:number;
  private video_A_display:string;
  private video_B_display:string;
  private position_counter:number;
  private onVideo:string;
  private playingA:boolean;
  private playingB:boolean;
  private isOpen:string;
  private is_playing_a:boolean;
  private is_playing_b:boolean;
  private is_first_time:boolean;
  private has_last_event:boolean;
  private counter_events:number;
  private control_restart:boolean;
  private on_cover_page:boolean;
  private clip_cover:any;
  private registered:Registered;
  private project:Project;
  private img_cover:boolean;
  private fade_layer:boolean;
  private heigh_marker:number;
  private width_marker:number;
  private top_marker:number;
  private left_marker:number;
  private control_to_marker:boolean;
  private width_video_layer:number;
  private height_video_layer:number;

  //-------------Movil-------------
  mobileQuery: MediaQueryList;
  private _mobileQueryListener: () => void;

  constructor(
    private _brokerService:BrokerService,
    public dialog: MatDialog,
    private _snackBar:MatSnackBar,
    private _authService:AuthService,
    private _projectsService:ProjectsService,
    private deviceService: DeviceDetectorService,
    changeDetectorRef: ChangeDetectorRef, media: MediaMatcher,
  ) {
    this.isOpen="closed";
    this.is_playing_a=false;
    this.is_playing_b=false;
    this.is_first_time=true;
    this.events=[];
    this.events_a=[];
    this.events_b=[];
    this.has_last_event = false;
    this.on_cover_page = false;
    this.registered = new Registered();
    this.mobileQuery = media.matchMedia('(max-width: 770px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
    this.project = new Project("");
    this.img_cover=false;
    this.fade_layer=false;
    this.width_video_layer=0;
    this.height_video_layer=0;
  }

  ngOnInit(){
    //console.log("ngOnInit");
    this.defaultMarker();
    this.getVideoLayerDimensions();
    this.getProject();
    this.getRegistered();
    this.video_A_display = "block";
    this.video_B_display = "none";
    this.listenPreviewStart();
  }
  

  ngOnDestroy() {
    //console.log("ngOnDestroy");
    this.mobileQuery.removeListener(this._mobileQueryListener);
    //this._brokerService.getPreviewModule().unsubscribe();
    clearInterval(this.current_interval);
    //this._brokerService.getCoverPageModuleMobile().unsubscribe();
    
  }

  getVideoLayerDimensions(){
    this.height_video_layer = document.getElementById('video_container').offsetHeight;
    this.width_video_layer = document.getElementById('video_container').offsetWidth;
  }

  getProject(){
    this.project = this._projectsService.getProjectOnStorage();
  }

  getRegistered(){
    this.registered = this._projectsService.getRegisteredOnStorage();
  }

  // metodo que se carga al estar listo el reproductor para realizar recortes
  onPlayerReady(api:VgAPI) {
    this.api = api;
    this.api.getDefaultMedia().subscriptions.rateChange.subscribe((data) => {
      this.api2.playbackRate = this.api.playbackRate;
    });
  }

  onPlayerReadyTwo(api:VgAPI){
    this.api2 = api;
    this.api2.getDefaultMedia().subscriptions.rateChange.subscribe((data) => {
      this.api.playbackRate = this.api2.playbackRate;
    });
  }

  listenPreviewStart(){
    setTimeout(() => {
      this._brokerService.getPreviewModule().subscribe(
        data=>{
          if(data!=undefined){
            this.video_A_display = "block";
            this.video_B_display = "none";
            this.isOpen="closed";
            this.onVideo="A";
            this.events = data;
            this.is_first_time = true;
            if(this.events.length>0){
              this.makeCover();
              this.makeEventsBody();
            }else{
              this._snackBar.open(".", "Cerrar", {
                duration: 5000,
              });  
            }
          }
       });
    }, 400);
   }

   makeCover(){
    if(this.events[0].cover!=null){
      this.img_cover=true;
      let aux_to_get_clip:any = this.events.splice(0,1)[0];
      this.clip_cover = aux_to_get_clip;
      let token=this._authService.getStoragedToken();
      let media_signed = this.clip_cover.cover.replace("media","signed");
      this.clip_cover.cover = media_signed+"?Authorization="+token;
    }
   }

   makeEventsBody(){
    this.counter_events = this.events.length;
    for(let i=0; i<this.events.length; i++){
       let i_format = i+1;
        if(this.isOdd(i_format)){
          this.events_b.push(this.events[i]);
        }else{
          this.events_a.push(this.events[i]);
        }
     }
     this.initLoad();
   }

   initLoad(){
    this.loadEventOnAVideo();
   }

  fade(){
    this.isOpen="open";
    this.fade_layer=true;
    setInterval(() => {
      this.isOpen="closed";
      this.fade_layer=false;
    }, 1);
  }

  firstEventCover(){
    this.on_cover_page=true;
    if(this.clip_cover!=undefined){
      setTimeout(() => {
        this.img_cover=false;
        this.on_cover_page=false;
        this.loadNextVideo();
      }, 7000);
    }else{
      this.on_cover_page=false;
      this.loadNextVideo();
    }
  }

  loadNextVideo(){  
    //console.log("loadNextVideo");
    console.log("LOAD CLIP ---------------------------------");
    console.log(this.counter_events);
    clearInterval(this.current_interval);
    if(this.counter_events==0){
      this.api.pause();
      this.api2.pause();
      this._brokerService.initEventBtnPlay(1);
    }else{
      if(this.onVideo=="A"){
        this.video_A_display = "block";
        this.video_B_display = "none";
        this.playAVideo();
        this.onVideo="B";
      }else if(this.onVideo=="B"){
        this.video_A_display = "none";
        this.video_B_display = "block";
        this.playBVideo();
        this.onVideo="A";
      }
    }
  }

   // precarga nueva media al reproductor A
   loadEventOnAVideo(){
    console.log("loadEventOnAVideo");
    if(this.events_a.length>0){
      this.current_event_first = this.events_a.splice(0,1)[0];
      let token=this._authService.getStoragedToken();
      this.current_event_first.media.replace("media","signed");
      let media_signed = this.current_event_first.media;
      media_signed = media_signed.replace("media","signed");
      this.preview_video = media_signed+"?Authorization="+token;
      (<VgMedia>this.api.getMasterMedia()).loadMedia();
      this.is_playing_a = false;
      this.api.getDefaultMedia().subscriptions.canPlay.subscribe(() => {
        if(this.is_playing_a==false){
          this.is_playing_a = true;
          this.ifSaturation();
          this.ifAudio();
          this.setSpeed();
          this.setStartSecond(this.current_event_first.start);
        }
      });
    }
  }

  setStartSecond(start_second){
    //console.log("setStartSecond");
    this.api.seekTime(start_second);
    this.api.pause();
    if(this.is_first_time){
      this.firstEventCover();
      this.is_first_time=false;
    }
  }

  // precarga nueva media al reproductor A
  loadEventOnBVideo(){
    console.log("loadEventOnBVideo");
    if(this.events_b.length>0){
      this.current_event_second = this.events_b.splice(0,1)[0];
      let token=this._authService.getStoragedToken();
      let media_signed = this.current_event_second.media;
      media_signed = media_signed.replace("media","signed");
      this.preview_video2 = media_signed+"?Authorization="+token;
      (<VgMedia>this.api2.getMasterMedia()).loadMedia();
      this.is_playing_b = false;
      this.api2.getDefaultMedia().subscriptions.canPlay.subscribe(() => {
        if(this.is_playing_b==false){
          this.is_playing_b = true;
          this.ifSaturation2();
          this.ifAudio2();
          this.setSpeed2();
          this.setStartSecondB(this.current_event_second.start);
        }
      });
    }
  }

  setStartSecondB(start_second){
    console.log("setStartSecondB");
    this.api2.seekTime(start_second);
    this.api2.pause();
  }

  // ejecuta reproduccion de video parte a
  playAVideo(){
    //console.log("playAVideo");
    this.pauseBvideo();
    this.api.play();
    this.doPreview();
    this.counter_events = this.counter_events - 1;
  }

  // ejecuta reproduccion de video parte b
  playBVideo(){
    //console.log("playBVideo");
    this.pauseAvideo();
    this.api2.play();
    this.doPreview2();
    this.counter_events = this.counter_events - 1;
  }

  // detrmina si es par o impar
  isOdd(num_param){ 
    //console.log("isOdd");
    let par=true;
    let num = num_param % 2;
    if(num==0){
      par = true;
    }else if(num==1){
      par = false;
    }
    return par;
  }

  // controla la previsualizacion del video parte a
  doPreview(){
    //console.log("doPreview");
    this.control_to_marker = true;
    this.loadEventOnBVideo();
    this.current_interval = setInterval(() => {
      console.log("Current time: "+this.api.currentTime+" --->  End time: " + this.current_event_first.end);
      let end = this.current_event_first.end;
      if(this.current_event_first.type_event=="marker" && this.control_to_marker==true){
        let two_second_more:number = parseFloat(this.current_event_first.marker_begin_at) + 2;
        if(this.api.currentTime>=this.current_event_first.marker_begin_at && this.api.currentTime<two_second_more){
          this.loadMarker(this.current_event_first, this.api);
        }
      }
      if(this.api.currentTime>=this.current_event_first.end){
        this.loadNextVideo();
      }
      if(this.api.currentTime>=this.current_event_first.end-1){
        this.fade();
        
      }
    }, 1);
  }

  // controla la previsualizacion del video parte b
  doPreview2(){
    //console.log("doPreview2");
    this.loadEventOnAVideo();
    this.control_to_marker = true;
    this.current_interval = setInterval(() => {
      console.log("Current time: "+this.api2.currentTime+" --->  End time: " + this.current_event_second.end);
      let end = this.current_event_second.end;
      if(this.current_event_second.type_event=="marker" && this.control_to_marker==true){
        let two_second_more:number = parseFloat(this.current_event_second.marker_begin_at) + 2;
        if(this.api2.currentTime>=this.current_event_second.marker_begin_at && this.api2.currentTime<two_second_more){
          console.log(this.api2.currentTime);
          this.loadMarker(this.current_event_second, this.api2);
        }
      }
      
      if(this.api2.currentTime>=this.current_event_second.end){
        this.loadNextVideo();
      }
      if(this.api2.currentTime>=this.current_event_second.end-1){
        this.fade();
      }
    }, 1);
  }

  // ajusta el tamaño del marker y el tiempo de ejecución
  loadMarker(current_event, api){
    this.control_to_marker=false;
    
    if(current_event.zoom_marker>0 && current_event.distance_y>0 && current_event.distance_x>0){

      let relation = this.detectScreenRelation(current_event.width_container_marker, this.width_video_layer);

      this.width_marker=current_event.zoom_marker * relation;
      this.heigh_marker = current_event.zoom_marker * relation;
      this.top_marker = current_event.distance_y * relation;
      this.left_marker = current_event.distance_x * relation;
      
      if(api.play){
        api.pause();
        setTimeout(() => {
          this.defaultMarker();
          api.play();
        }, 2000);
      }
      
    }
  }
  //detecta realcion entre tamaños de contenedores de la mira
  detectScreenRelation(width_source, width_preview){
    return width_preview/width_source;
  }
  // setea en 0 el tamañao y posicion del marker
  defaultMarker(){
    //console.log("defaultMarker");
    this.width_marker=0;
    this.heigh_marker = 0;
    this.top_marker = 0;
    this.left_marker = 0;
  }

  pauseAvideo(){
    //console.log("pauseAVideo");
    if(this.api.play){
      this.api.pause();
    }
  }

  pauseBvideo(){
    //console.log("pauseBVideo");
    if(this.api2.play){
      this.api2.pause();
    }
  }

  setSpeed(){
    //console.log("setSpeed");
    this.api.playbackRate = this.current_event_first.speed;
  }
  
  ifSaturation(){
    //console.log("ifSaturation");
    this.saturation = this.current_event_first.saturation;
  }
  ifAudio(){
    //console.log("ifAudio");
    let audio = this.current_event_first.audio;
    if(audio){
      this.api.volume = 1;
    }else{
      this.api.volume = 0;
    }
  }

  setSpeed2(){
    console.log("setSpeed2");
    this.api2.playbackRate = this.current_event_second.speed;
  }
  ifSaturation2(){
    console.log("ifSaturation2");
    this.saturation2 = this.current_event_second.saturation;
  }
  ifAudio2(){
    console.log("ifAudio2");
    let audio = this.current_event_second.audio;
    if(audio){
      this.api2.volume = 1;
    }else{
      this.api2.volume = 0;
    }
  }

  addCoverPage(){
    //console.log("addCoverPage");
    const dialogRef = this.dialog.open(CoverPageComponent,{
      height: '455x',
      width: '300px',
      data: { }
    });
    dialogRef.afterClosed().subscribe(result => {
      this.sendClipTo(result);
    });
  }

  sendClipTo(result){
    //console.log("sendClipTo");
    if(result!=undefined){
      if(result.status==1){
        this._brokerService.initCoverPage(result);
      }
    }
  }

  playVideo(){
    if(this.onVideo=="B"){
      if(this.api.pause){
        this.playAVideo();
      }
    }else if(this.onVideo=="A"){
      if(this.api2.pause){
        this.playBVideo();
      }
    }
  }

  pauseVideo(){
    if(this.onVideo=="B"){
      if(this.api.play){
        this.pauseAvideo();
      }
    }else if(this.onVideo=="A"){
      if(this.api2.play){
        this.pauseBvideo();
      }
    }
  }

}
