import { Component, OnInit, Input, Output, EventEmitter, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { Project } from 'src/app/models/project';
import { Exports } from 'src/app/models/editor';
import { ProjectsService } from 'src/app/services/projects/projects.service';
import Swal from 'sweetalert2';
import { ProjectDeleteComponent } from '../../../components/projects/project-delete/project-delete.component';
import { ProjectEditComponent } from '../../../components/projects/project-edit/project-edit.component';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-project-item',
  templateUrl: './project-item.component.html',
  styleUrls: [ './project-item.component.scss']
})
export class ProjectItemComponent implements OnInit {
  @Input('title') title: string;
  @Input('description') description: string;
  @Input('id') id;
  @Output() public brake = new EventEmitter<any>();
  @Output() public remove_project = new EventEmitter<any>();
  @Output() public refresh = new EventEmitter<any>();

  constructor(
    public dialog: MatDialog,
    private _projectsService:ProjectsService,
  ) { }

  ngOnInit() {
    //console.log(this.id);
  }

  toEditor(){
    this.brake.emit(this.id);
  }

  signalToRemove(result){
    this.remove_project.emit(result);
  }

  signalToRefresh(result){
    this.refresh.emit(result);
  }

  deleteProject(){
    const dialogRef = this.dialog.open(ProjectDeleteComponent,{
      height: '140px',
      width: '300px',
      data: { project_id:this.id }
    });
  
    dialogRef.afterClosed().subscribe(result => {
      if(result!=undefined){
          this.signalToRemove(result);
      }
    });
  }

  editProject(){
    const dialogRef = this.dialog.open(ProjectEditComponent,{
      height: '455x',
      width: '300px',
      data: { project_id:this.id }
    });
  
    dialogRef.afterClosed().subscribe(result => {
      if(result!=undefined){
          this.signalToRefresh(result);
      }
    });
  }
}
