import { Component, OnInit, Inject } from '@angular/core';
import {Event, Clic} from '../../../models/editor';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as uuid from 'uuid';

@Component({
  selector: 'app-add-event',
  templateUrl: './add-event.component.html',
  styleUrls: ['./add-event.component.css']
})
export class AddEventComponent implements OnInit {
  private minute = new FormControl('', [Validators.required]);
  private second = new FormControl('', [Validators.required]);
  private description = new FormControl('', [Validators.required, Validators.minLength(5), Validators.maxLength(35)]);
  private seconds:any[];
  private minutes:any[];
  private event:Event;
  constructor(
    public dialogRef: MatDialogRef<AddEventComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { 
    this.seconds = [];
    this.minutes = [];
    this.event = new Event();
  }

  ngOnInit() {
    this.event = this.data.event;
    this.formatTime();
    this.setValues();
  }

  setValues(){
    this.description.setValue(this.event.description);
  }

  getDescriptionError(){
    return this.description.hasError('required') ? 'Por favor ingresa una descripción' :
            this.description.hasError('minlength') ? 'Min. 10 caracteres':
              this.description.hasError('maxlength') ? 'Max. 500 caracteres':
            '';
  }

  formatTime(){
    for(let i=0; i<60 ; i++){
      if(i<10){
        this.seconds.push("0"+i);
      }else{
        this.seconds.push(String(i));
      }
    }
    for(let i=0; i<10 ; i++){
      this.minutes.push(String(i));
    }
  }

  validForm(){
    let valid=true;
    if(this.description.invalid){
      valid = false;
    }
    return valid;
  }

  updateEvent(){
    if(this.validForm()){
      this.event.description = this.description.value;
      this.closeComponent();
    }
    
  }


  closeComponent(){
    this.dialogRef.close({data:this.event});
  }

}
