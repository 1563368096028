import { Injectable } from '@angular/core';
import {AuthService} from '../auth/auth.service';
import { GLOBAL } from '../../../environments/environment';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import 'rxjs/add/operator/do';
import { Router, Params, ActivatedRoute } from '@angular/router';

@Injectable()
export class InterceptorService {
  private token:string;
  private API_URL:string;
  constructor(
    private _authService:AuthService,
    private _route:ActivatedRoute,
    private _router:Router,
  ) {
    this.token = "";
    this.API_URL=GLOBAL.apiMVSVideo;
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.token=this._authService.getStoragedToken();
    if(this.token){
      request = request.clone({
        setHeaders: {
          Authorization: `Token `+this.token
        },
        url:this.API_URL+request.url
      });
    }else{
      request = request.clone({
        url:this.API_URL+request.url
      });
    }
    return next.handle(request).do(event => {
    },
    err=>{
      if (err instanceof HttpErrorResponse && (err.status === 401 || err.status === 403)) {
        this._authService.deleteStorageToken();
        this._router.navigate(['/']);
      }
    });  
  }
}
