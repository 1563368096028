import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import Swal from 'sweetalert2';
import { FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-adjust-time-cutter',
  templateUrl: './adjust-time-cutter.component.html',
  styleUrls: ['./adjust-time-cutter.component.css']
})
export class AdjustTimeCutterComponent implements OnInit {

  private init_time = new FormControl('', [Validators.required, Validators.minLength(3), Validators.maxLength(34)]);
  private end_time = new FormControl('', [Validators.required, Validators.minLength(3), Validators.maxLength(34)]); 
  private total_time:string;
  private valid_time:boolean;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<AdjustTimeCutterComponent>,
  ) { }

  // inciar el componente
  ngOnInit() {
    this.init_time.setValue(this.data.init_time);
    this.end_time.setValue(this.data.end_time);
    this.total_time = this.data.total_time;
    this.valid_time= true;
  }
  getInitTimeError(){
    return this.init_time.hasError('required') ? 'Por favor ingresa el tiempo inicial' :
            this.init_time.hasError('minlength') ? 'Ingresa el formato correcto':
              this.init_time.hasError('maxlength') ? 'Ingresa el formato correcto':
            '';
  }

  getEndTimeError(){
    return this.end_time.hasError('required') ? 'Por favor ingresa el tiempo final' :
            this.end_time.hasError('minlength') ? 'Ingresa el formato correcto':
              this.end_time.hasError('maxlength') ? 'Ingresa el formato correcto':
            '';
  }


  aplyChanges(){
    if(this.validTime()){
      this.closeModal();
    }else{
      this.valid_time = false;
    }
  }

  validTime(){
    let valid = true;
    this.valid_time = true;
    let end_time=this.end_time.value.split(":");
    let total_time=this.total_time.split(":");
    let init_time=this.init_time.value.split(":");
    let d1=new Date(parseInt("2001",10),(parseInt("01",10))-1,parseInt("01",10),parseInt(end_time[0],10),parseInt(end_time[1],10),parseInt(end_time[2],10));
    let d2=new Date(parseInt("2001",10),(parseInt("01",10))-1,parseInt("01",10),parseInt(total_time[0],10),parseInt(total_time[1],10),parseInt(total_time[2],10));
    let d3=new Date(parseInt("2001",10),(parseInt("01",10))-1,parseInt("01",10),parseInt(init_time[0],10),parseInt(init_time[1],10),parseInt(init_time[2],10));
    let dd1=d1.valueOf();
    let dd2=d2.valueOf();
    let dd3=d3.valueOf();

    if(dd1>=dd2 || dd1<dd3){
      valid = false 
    }


    if(valid){
      var dif = d3.getTime() - d1.getTime();
      var Seconds_from_T1_to_T2 = dif / 1000;
      var Seconds_Between_Dates = Math.abs(Seconds_from_T1_to_T2);
      if(Seconds_Between_Dates>40 || Seconds_Between_Dates<2){
        valid = false 
      }
    }

    return valid;
  }

  closeModal(){
    this.dialogRef.close({init_time:this.init_time.value, end_time:this.end_time.value});
  }
}
  

