import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';

@Injectable({
  providedIn: 'root'
})
export class BrokerService {

  public preview = new BehaviorSubject(undefined);
  public play_pause_preview = new BehaviorSubject(undefined);
  public add_cover_page = new BehaviorSubject(undefined);
  public refresh_files = new BehaviorSubject(undefined);
  public event = new BehaviorSubject(undefined);
  public cover_mobile = new BehaviorSubject(undefined);
  public btn_play = new BehaviorSubject(undefined);

  constructor() { }

  //para lanzar el preview
  getPreviewModule() {
    return this.preview;
  }
  // inicializar el preview
  initPreview(param:number) {
    this.preview.next(param);
  }
  //para pausar o reproducir el preview
  getPlayPausePreviewModule() {
    return this.play_pause_preview;
  }
  // inicializar el pausar o reproducir el preview
  initPlayPausePreview(param:number) {
    this.play_pause_preview.next(param);
  }
  //para lanzar el add cover page
  getCoverPageModule() {
    return this.add_cover_page;
  }
  // inicializar el add cover page
  initCoverPage(param:number) {
    this.add_cover_page.next(param);
  }

  //para lanzar el add cover page from mobile
  getCoverPageModuleMobile() {
    return this.cover_mobile;
  }
  // inicializar el add cover page from mobile
  initCoverPageMobile(param:number) {
    this.cover_mobile.next(param);
  }

  //para lanzar el add cover page
  getRefreshFilesModule() {
    return this.refresh_files;
  }
  // inicializar el add cover page
  initRefreshFiles(param:number) {
    this.refresh_files.next(param);
  }

  //para lanzar el broker de un event
  getEventModule() {
    return this.event;
  }
  // inicializar el broker de un event
  initEvent(param:any) {
    this.event.next(param);
  }

  //para lanzar el broker de btn de preview
  getEventBtnPlay() {
    return this.btn_play;
  }
  // inicializar el broker de btn de preview
  initEventBtnPlay(param:any) {
    this.btn_play.next(param);
  }
}
