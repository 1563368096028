import { Injectable } from '@angular/core';
//import { HttpClient } from  '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private name_token:string;
  constructor(
    //private _http: HttpClient
  ) { 
    this.name_token="tk_mvs_video";
  }
  
  // almacena token en local storage
  setStoragedToken(token:string){
    this.deleteStorageToken();
    let storageWell:boolean = true;
    if (typeof(Storage) !== "undefined") {
        if(token!=null){
          localStorage.setItem(this.name_token, token);
        }else{
          localStorage.setItem(this.name_token, "none");
          storageWell=false;
        }
    }else {
        alert("Local Storage doesn't support, Update your navigator");
        storageWell=false;
    }
    return storageWell;
  }
  
  // recuperar token de local storage
  getStoragedToken():string{
    let token:string;
    if(typeof(Storage)!="undefined"){
      token=localStorage.getItem(this.name_token);
    }else {
        token=null;
        alert("Local Storage doesn't support, Update your navigator");
    }
    return token;
  }
  // eliminar token local de local storage
  deleteStorageToken(){
    if(typeof(Storage)!="undefined"){
      localStorage.removeItem(this.name_token);
    }
  }
}

