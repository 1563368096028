import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Clic, File } from '../../../models/editor';
import { ProjectsService } from '../../../services/projects/projects.service';
import { Options } from 'ng5-slider';
import { VgAPI,VgMedia } from 'videogular2/compiled/core';
import {AuthService} from '../../../services/auth/auth.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-adjusment-clip',
  templateUrl: './adjusment-clip.component.html',
  styleUrls: ['./adjusment-clip.component.css','./styled-slider.component.scss']
})
export class AdjusmentClipComponent implements OnInit {
  public clip:Clic;
  public file:File;
  public audio_video:boolean;
  public speed: number = 1;
  public fps:number;
  private is_playing:boolean;
  public options_speed_time_line: Options = {
      showTicksValues: true,
      stepsArray: [
        {value: 0.5, legend: '0.5X'},// 1 frame por segundo
        {value: 1, legend: '1X'},// 1 frame por segundo
        {value: 2, legend: '2X'},// 1 frames por 10 second
        {value: 4, legend: '4X'}, // un frame por clip
      ]
  };

  public saturation:number;
  options_saturation: Options = {
    floor: 0,
    ceil: 10
  };
  public to_response_to_parent:any;
  public video_source:string;
  private api:VgAPI;
  private interval_control_end:any;
  public loader:boolean;
  public fullScreen:boolean; // validar si la pantalla esta full screen
  constructor(
    private _snackBar:MatSnackBar,
    private _projectsService:ProjectsService,
    public dialogRef: MatDialogRef<AdjusmentClipComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _authService:AuthService
  ) {
    this.clip = new Clic();
    this.to_response_to_parent={
      volumen_start:"",
      volumen_end:"",
      volume_body:"",
      volume_changed:false,
      time_start:"",
      time_end:"",
      time_body:"",
      time_changed:false,
      saturation_start:"",
      saturation_end:"",
      saturation_body:"",
      saturation_changed:"",
      clip_id:0
    };
    this.file = new File();
    this.fps = 30;
    this.is_playing = false;
    this.loader = true;
    this.fullScreen=false;
   }

  ngOnInit() {
    this.getDataClic();
  }

  getDataClic(){
    let route = "clips/"+this.data.id+"/";
    this._projectsService.getClic(route).subscribe(
      success=>{
        let clip:any = success;
        clip = clip.body;
        this.clip = clip;
        this.video_source = this.clip.file;
        this.getFile(this.clip.file);
        this.formatVolumeInterface();
        this.formatSpeedInterface();
        this.formatSaturationInterface();
      },
      error=>{
        console.log(error);
      }
    );
  }

  getFile(file:string){
    let file_id = file.split('/')[4];
    this._projectsService.getFile(file_id).subscribe(
      success=>{
        let file:any = success;
        file = file.body;
        this.file = file;
        let token=this._authService.getStoragedToken();
        this.setMedia(this.file.media+"?Authorization="+token);
      },
      error=>{
        Swal.fire({
          type: "error",
          text: "Error al cargar el file del clip."
        });

      }
    );
  }

  setMedia(file){
    this.video_source = file.replace("media","signed");
    (<VgMedia>this.api.getMasterMedia()).loadMedia();
        this.api.getDefaultMedia().subscriptions.loadedMetadata.subscribe(() => {
          this.setSpeed(); 
        });
        this.api.getDefaultMedia().subscriptions.canPlay.subscribe(() => {
          if(this.is_playing==false){
            this.setStartSecond(this.clip.start);
          }
        });
  }

  setStartSecond(start_second){
    this.is_playing = true;
    this.api.seekTime(start_second);
    this.api.pause();
    setTimeout(() => {
      this.loader = false;
    }, 1500);
  }


resStartMedia(){
  this.setStartSecond(this.clip.start);
}

controlEndClip(){
   this.interval_control_end = setInterval(() => {
     console.log("Current time: "+ this.api.currentTime);
     console.log("End time: "+this.clip.end);
     if(this.api.currentTime==undefined || this.clip.end==undefined){
       clearInterval(this.interval_control_end);
     }
     if(this.api.currentTime>=this.clip.end){
       clearInterval(this.interval_control_end);
       this.reStartClip();
     }
   }, 1);
}

 pauseClip(){
   this.api.pause();
 }

 playClip(){
   this.api.play();
   this.controlEndClip();
 }

 reStartClip(){
   this.is_playing=false;
   this.resStartMedia();
 }

  onPlayerReady(api:VgAPI) {
    this.api = api;
    console.log(this.api.getDefaultMedia())
    this.api.fsAPI.onChangeFullscreen.subscribe(
      event => {
          this.onChangeFullscreen(event);
      }
    );
  }

  onChangeFullscreen(event){
    this.fullScreen = event;
  }

  setSpeed(){
    this.api.playbackRate = this.speed;
  }

  formatSpeedInterface(){
    let time = this.clip.json.time.Points;
    this.speed = this.clip.json.speed;
    this.api.playbackRate = this.speed;
    this.to_response_to_parent.time_start = this.clip.json.speed;
    if(time.lenght == 0 || time == undefined || this.speed == undefined){
      Swal.fire({
        type: "warning",
        text: "Error al cargar la velocidad del clip, se solucionara al aplicar nuevos ajustes sobre el clip."
      });
    }
  }

  formatVolumeInterface(){
    let points = this.clip.json.volume.Points;
    if(points.lenght == 0 || points == undefined){
      Swal.fire({
        type: "warning",
        text: "Error al cargar el volumen del clip, se solucionara al aplicar nuevos ajustes sobre el clip."
      });
      
    }else if(points.length==1){
      this.audio_video=false;
      this.to_response_to_parent.volume_start = false;
    }else{
      let volumen_level = points[0].co.Y;
      if(volumen_level<1){
        this.audio_video=true;
        this.to_response_to_parent.volume_start = true;
      }else{
        this.audio_video=false;
        this.to_response_to_parent.volume_start = false;
      }
    }
    this.changeAudio();
  }

  formatSaturationInterface(){
    this.saturation=1;
    let effects = this.clip.json.effects;
    this.to_response_to_parent.saturation_start = 1;
    for(let i=0;i<effects.length;i++){
      if(effects[i].class_name=="Saturation"){
        this.saturation = effects[i].saturation.Points[0].co.Y;
        this.to_response_to_parent.saturation_start = this.saturation;
      }
    }
  }

  apllyChanges(){
    this.loader = true;
    this.formatVolume();
    this.formatSaturation();
    let validate_changes = this.validateChanges();
    if(validate_changes){
      this.updateClip();
    }else{
      this.closeComponent();
    }
  }

  formatVolume(){
    let volume = {}
    let y1=0;
    let y2=0;
    let x1 = (this.clip.start * this.fps)+1;
    let x2 = (this.clip.json.real_end * this.fps)+1;
    if(this.audio_video==false){
      y1 = 1;
      y2 = 1;
    }else{
      y1 = 0;
      y2 = 0;
    }
    let points = [];
    let start_object={
      "interpolation": 1,
      "co": {
          "X": x1,
          "Y": y1
      }
    }
    let end_object={
      "interpolation": 1,
      "co": {
          "X": x2,
          "Y": y2
      }
    }
    points.push(start_object);
    points.push(end_object);
    this.clip.json.volume = {};
    this.clip.json.volume.Points = points;
    this.to_response_to_parent.volume_body = points;
  }

  formatSpeed(){
    this.clip.json.speed = String(this.speed);
    let start_clip = this.clip.start;
    let end_clip = parseInt(this.clip.json.real_end);
    let LC = end_clip - start_clip;
    //let Ndur = Math.round(LC/this.speed);
    let Ndur = LC/this.speed;
    let NEnd =  start_clip + Ndur
    let X1 = (start_clip * this.fps)+1;
    let X2 = (NEnd * this.fps)+1;
    let Y1 = X1;
    let Y2 = (end_clip * this.fps)+1;
    
    let points = [];
    let start_object={
      "interpolation": 1,
      "co": {
          "X": X1,
          "Y":Y1
      }
    }
    let end_object={
      "interpolation": 1,
      "co": {
          "X": X2,
          "Y": Y2
      }
    }

    this.clip.end = NEnd;
    // agrega coordenadas
    points.push(start_object);
    points.push(end_object);
    this.clip.json.time = {};
    this.clip.json.time.Points = points;
    this.clip.json.speed = this.speed;
    this.to_response_to_parent.time_body = points;
  }

  formatSaturation(){
    let effects = [];
    let body = {
      "class_name": "Saturation",
      "description": "Adjust the color saturation.",
      "duration": 0.0,
      "has_audio": false,
      "has_video": true,
      "id": "",
      "name": "Color Saturation",
      "order": 0,
      "saturation": {
              "Points": [
                  {
                      "co": {
                          "X": 1.0,
                          "Y": this.saturation
                      },
                      "interpolation": 2
                  }
              ]
      },
      "short_name": "",
      "type": "Saturation"
    };
    effects.push(body);
    this.clip.json.effects = effects;
    this.to_response_to_parent.saturation_body = body;
  }

  updateClip(){
    this._projectsService.updateClic(this.clip, this.clip.id).subscribe(
      success=>{
        Swal.fire({
          type: "success",
          text: "Los ajustes han sido aplicados correctamente."
        });
        this.closeComponent();
      },
      error=>{
        Swal.fire({
          type: "error",
          text: "Error al aplicar los ajustes."
        });
      }
    );
  }

  validateChanges(){
    let changes = false;
    if(this.to_response_to_parent.volume_start==this.audio_video){
      this.to_response_to_parent.volume_changed = false;
    }else{
      this.to_response_to_parent.volume_changed = true;
    }
    if(this.to_response_to_parent.time_start==this.speed){
      this.to_response_to_parent.time_changed = false;
    }else{
      this.to_response_to_parent.time_changed = true;
    }
    if(this.to_response_to_parent.saturation_start==this.saturation){
      this.to_response_to_parent.saturation_changed = false;
    }else{
      this.to_response_to_parent.saturation_changed = true;
    }
    if(this.to_response_to_parent.time_changed==true || this.to_response_to_parent.volume_changed==true || this.to_response_to_parent.saturation_changed == true){
      changes = true;
    }
    return changes;
  }

  closeComponent(){
    this.to_response_to_parent.clip = this.clip;
    this.loader = false;
    this.dialogRef.close({data:this.to_response_to_parent});
  }

  // cambia la velocidad
  userChangeSpeed(event){
    this.speed = event.value;
    this.formatSpeed();
    this.api.playbackRate = this.speed;
  }

  // cambia la saturación
  userChangeSaturation(event){
    this.saturation = event.value;
    this.to_response_to_parent.saturation_end = this.saturation;
  }

  changeAudio(){
    if(this.audio_video){
      this.api.volume = 0;
    }else{
      this.api.volume = 1;
    }
  }




}


