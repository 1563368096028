import { Component, OnInit, Inject } from '@angular/core';
import { Registered, Project} from '../../../models/project';
import { VideoSource, File} from '../../../models/editor';
import { ProjectsService } from '../../../services/projects/projects.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { BrokerService } from '../../../services/broker/broker.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-add-source-to-project',
  templateUrl: './add-source-to-project.component.html',
  styleUrls: ['./add-source-to-project.component.css']
})
export class AddSourceToProjectComponent implements OnInit {

  private registered:Registered;
  private videoSources:VideoSource[];
  private project:Project;
  private files:File[];
  private loader:boolean;
  constructor(
    private _projectsService:ProjectsService,
    private _snackBar:MatSnackBar,
    public dialogRef: MatDialogRef<AddSourceToProjectComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _brokerService:BrokerService
  ) {
    this.registered = new Registered();
    this.videoSources=[];
    this.files = [];
    this.loader = true;
   }

  ngOnInit() {
    this.getProject();
  }

  getProject(){
    this.project = this._projectsService.getProjectOnStorage();
    this.getRegistered();
  }

  getRegistered(){
    this.registered = this._projectsService.getRegisteredOnStorage();
    this.videoSources = this.registered.fuentes;
    for(let i=0;i<this.videoSources.length; i++){
      this.videoSources[i].is_active = false;
    }
    this.getFiles();
  }

  getFiles(){
    this._projectsService.getFiles(this.project.id).subscribe(
      success=>{
        let success_body:any = success; 
        console.log(this.videoSources);
        if(success_body.body.results.length>0){
          for(let i=0;i<success_body.body.results.length;i++){
            let media = success_body.body.results[i].media;
            let name = media.split("/")[7];
            if(success_body.body.results[i].json.media_type=="video" && name!="mira.mov"){
              this.files.push(success_body.body.results[i])
            }
          }
        }
        this.validateSource();
        console.log(this.files);
      },
      error=>{
        Swal.fire({
          type: "error",
          text: "Error al cargar las fuentes"
        });
      }
    );
  }

  validateSource(){
    //debugger;
    for(let i= this.videoSources.length - 1; i >= 0; i--){
    //for(let i=0; i<this.videoSources.length; i++){
      let videoSources_source = this.videoSources[i].title;
      for(let j=0; j<this.files.length; j++){
        let files_source = this.files[j].json.title;
        if(videoSources_source==files_source){
          this.videoSources.splice(i,1);
          //this.videoSources[j].is_active=true;
          break;
        }
      }
    }
    this.loader = false;
  }

  close(){
   this.dialogRef.close();
  }

  changeSource(source, event){
    console.log(event);
    if(event==true){
      this.loader=true;
      this.addStandarFile(this.project.url, source);
    }
  }

  deleteFile(file_id){
    this._projectsService.deleteFile(file_id).subscribe(
      success=>{
        console.log("file deleted");
      },
      error=>{
        Swal.fire({
          type: "error",
          text: "Error al actualizar el file"
        });
      }
    );
  }

  addStandarFile(url_project, source){
    let name = source.url.split("/")[1];
    this._projectsService.addStandarFile(url_project, source.url, name, source.title).subscribe(
        success=>{
          console.log("Add file");
          Swal.fire({
            type: "success",
            text: "El video ha sido agregado al proyecto."
          });
          setTimeout(() => {
            this.refresh();
          }, 1000);
          //this.removeFromList(source);
      },
      error=>{
          //this.loading = false;
          Swal.fire({
            type: "success",
            text: "Error al agregar fuente."
          });
        }
    );
  }

  refresh(): void {
    window.location.reload();
  }

  /*
  removeFromList(source){
    let videoSources_source = source.url.split("/")[1];
      for(let j=0; j<this.videoSources.length; j++){
        let files_source = this.videoSources[j].url.split("/")[1];
        if(videoSources_source==files_source){
          this.videoSources.splice(j,1);
          //this.deleteFile(this.files[j].id);
        }
      }
    setTimeout(() => {
      this.refresFiles();
    }, 1500);
    
  }

  refresFiles(){
    this._brokerService.initRefreshFiles(1);
    this.loader=false;
  }
  */

}
