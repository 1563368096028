import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class SourceService {

  constructor(
    private _http:HttpClient
  ) { }

  getSources(){
    return this._http.get("./source.json");
  }

}
