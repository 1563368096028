import { Component, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { MediaMatcher } from '@angular/cdk/layout';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnDestroy {

  //-------------Movil-------------
  mobileQuery: MediaQueryList;
  private _mobileQueryListener: () => void;

  constructor(changeDetectorRef: ChangeDetectorRef, media: MediaMatcher, private router: Router) {
    this.mobileQuery = media.matchMedia('(max-width: 770px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }


  goToOriginalPageItem(item) {
    Swal.fire({
      title: '¿Quieres salir del editor?',
      text: "",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText: 'No',
      confirmButtonText: 'Si, salir del editor'
    }).then((result) => {
      if (result.value) {
        switch (item) {
          case 'home':
            window.location.href = "https://mvstars.com/";
            break;
          case 'nosotros':
            window.location.href = "https://mvstars.com/nosotros/cod2/";
            break;

          case 'productos':
            window.location.href = "https://mvstars.com/producto/cod3/";
            break;

          case 'blog':
            window.location.href = "https://mvstars.com/blog/cod5/";
            break;

          case 'contacto':
            window.location.href = "https://mvstars.com/contactanos/cod6/";
            break;
        }
      }
    })
  }


  goToHome(){
    window.location.href = 'https://mvstars.com/mi-perfil/cod11/';
  }

  goToProjects(){
    this.router.navigate(["projects"]);
  }
}