import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
// carga rutas de routing.ts
import { routing, appRoutingProviders } from './app.routing';

// interceptor
import { InterceptorService } from './services/interceptor/interceptor.service';

//Angular material modules-------------------
import 'hammerjs';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatListModule} from '@angular/material/list';
import {MatTabsModule} from '@angular/material/tabs';

// material module
import { MaterialModule } from '../app/modules/material/material.module';

// material module
import { VideoPlayerModule } from './modules/video-player/video-player.module';


// slider 
import { Ng5SliderModule } from 'ng5-slider';

// drag and drop
import {DragDropModule} from '@angular/cdk/drag-drop';

// font awesome
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

// componente de audio
import { NgxAudioPlayerModule } from 'ngx-audio-player';

// dropzone
import { DropzoneModule } from 'ngx-dropzone-wrapper';
import { DROPZONE_CONFIG } from 'ngx-dropzone-wrapper';
import { DropzoneConfigInterface } from 'ngx-dropzone-wrapper';

// device detector
import { DeviceDetectorModule } from 'ngx-device-detector';

//dependencia para evitar el 404 en recarga de contexto
import { LocationStrategy, HashLocationStrategy} from '@angular/common';
//Sweet alert
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';

import { AppComponent } from './app.component';
import { ProjectsComponent } from './components/projects/projects.component';
import { ProjectsNewComponent } from './components/projects/projects-new/projects-new.component';
import { ProjectItemComponent } from './components/projects/project-item/project-item.component';
import { EditorComponent } from './components/editor/editor.component';
import { BadSessionComponent } from './components/bad-session/bad-session.component';
import { Error404Component } from './components/error404/error404.component';
import { PreviewVideoComponent } from './components/editor/preview-video/preview-video.component';
import { AdjusmentClipComponent } from './components/editor/adjusment-clip/adjusment-clip.component';
import { AdjustTransitionComponent } from './components/editor/adjust-transition/adjust-transition.component';

import { BrokerService } from './services/broker/broker.service';
import { GetMarkerComponent } from './components/editor/get-marker/get-marker.component';
import { AddEventComponent } from './components/editor/add-event/add-event.component';
import { CoverPageComponent } from './components/editor/cover-page/cover-page.component';
import { DeleteEntityComponent } from './components/editor/delete-entity/delete-entity.component';
import { AddSourceToProjectComponent } from './components/editor/add-source-to-project/add-source-to-project.component';
import { AdjusmentAudioClipComponent } from './components/editor/adjusment-audio-clip/adjusment-audio-clip.component';
import { LoadTokenComponent } from './components/load-token/load-token.component';
import {HelpComponent} from './components/help/help.component';
import { ProjectDeleteComponent } from './components/projects/project-delete/project-delete.component';
import { ProjectEditComponent } from './components/projects/project-edit/project-edit.component';
import { DeleteSourceComponent } from './components/editor/delete-source/delete-source.component';
import { AdjustTimeCutterComponent } from './components/editor/adjust-time-cutter/adjust-time-cutter.component';


 
const DEFAULT_DROPZONE_CONFIG: DropzoneConfigInterface = {
  // Change this to your upload POST address:
   url: 'https://httpbin.org/post',
   maxFilesize: 100,
   acceptedFiles: 'image/*'
 };

@NgModule({
  declarations: [
    AppComponent,
    ProjectsComponent,
    ProjectsNewComponent,
    ProjectItemComponent,
    EditorComponent,
    BadSessionComponent,
    Error404Component,
    PreviewVideoComponent,
    AdjusmentClipComponent,
    AdjustTransitionComponent,
    GetMarkerComponent,
    AddEventComponent,
    CoverPageComponent,
    DeleteEntityComponent,
    AddSourceToProjectComponent,
    AdjusmentAudioClipComponent,
    LoadTokenComponent,
    HelpComponent,
    ProjectDeleteComponent,
    ProjectDeleteComponent,
    ProjectEditComponent,
    DeleteSourceComponent,
    AdjustTimeCutterComponent
  ],
  imports: [
    BrowserModule,
    routing,
    BrowserAnimationsModule,
    MaterialModule,
    MatTabsModule,
    VideoPlayerModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    Ng5SliderModule,
    DragDropModule,
    DropzoneModule,
    SweetAlert2Module.forRoot(),
    NgxAudioPlayerModule,
    DeviceDetectorModule.forRoot()
  ],
  entryComponents:[
    ProjectsNewComponent,
    AdjusmentClipComponent,
    AdjustTransitionComponent,
    GetMarkerComponent,
    AddEventComponent,
    CoverPageComponent,
    DeleteEntityComponent,
    AddSourceToProjectComponent,
    AdjusmentAudioClipComponent,
    ProjectDeleteComponent,
    ProjectEditComponent,
    DeleteSourceComponent,
    AdjustTimeCutterComponent
  ],
  providers: [
    appRoutingProviders,
    BrokerService,
    {provide: LocationStrategy, useClass: HashLocationStrategy},
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptorService,
      multi: true
    },
    {
      provide: DROPZONE_CONFIG,
      useValue: DEFAULT_DROPZONE_CONFIG
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
