import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-bad-session',
  templateUrl: './bad-session.component.html',
  styleUrls: ['./bad-session.component.css']
})
export class BadSessionComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  goToLogin(){
    window.location.href = "https://mvstars.com/acceder/cod12/";
  }
}
