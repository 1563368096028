import { ModuleWithProviders} from '@angular/core';
import { Routes, RouterModule} from '@angular/router';
import { ProjectsComponent } from './components/projects/projects.component';
import { EditorComponent } from './components/editor/editor.component';
import { BadSessionComponent } from './components/bad-session/bad-session.component';
import { LoadTokenComponent } from './components/load-token/load-token.component';
import {HelpComponent} from './components/help/help.component';

const appRoutes:Routes =[
    {path:'',component:ProjectsComponent},
    {path:'help', component: HelpComponent},
    {path:'projects',component:ProjectsComponent},
    {path:'editor/:project_id',component:EditorComponent},
    {path:'init/:token',component:LoadTokenComponent},
    {path:'**',component:BadSessionComponent}
];

export const appRoutingProviders:any[]=[];
export const routing:ModuleWithProviders = RouterModule.forRoot(appRoutes);

