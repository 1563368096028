import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Router, Params, ActivatedRoute } from '@angular/router';
import { trigger,state,style,animate,transition} from '@angular/animations';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ProjectsNewComponent } from './projects-new/projects-new.component';
import { Project, Registered } from '../../models/project';
import { Exports } from '../../models/editor';
import { ProjectsService } from '../../services/projects/projects.service';
import { AuthService } from '../../services/auth/auth.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.scss'],
  animations: [
    trigger('visibilityChanged', [
        state('true' , style({ opacity: '0', display:'none' })), 
        state('false', style({ opacity: "1" , display:'block' })),
        transition('0 => 1', animate('.1s')),
        transition('1 => 0', animate('.1s'))
    ])
]
})
// componente utilizado para la gestion de los proyectos
export class ProjectsComponent implements OnInit {
  private token:string;
  private preloader:boolean;
  private projects:Project[];
  private registered:Registered;
  private exports:Exports[];
  
  constructor(
    private _route:ActivatedRoute,
    private _router:Router,
    public dialog: MatDialog,
    public _projectsService:ProjectsService,
    private _authService:AuthService,
  ) { 
    this.preloader = false;
    this.projects = [];
    this.registered = new Registered();
    this.exports=[];

  }

  
  ngOnInit() {
    //this.getToken();
    this.getProjects();
  }

  
  /*
  // extrae token de parametro de URL
  getToken(){
    this._route.params.forEach((params:Params)=>{
      this.token=params["token"];
      this.processToken();
    });
  }
  // procesa el token
  processToken(){
    console.log(this.token);
    if(this.token != undefined){
      this.getInfo();
      //this.getProjects();
    }
  }
  
  getInfo(){
    this._projectsService.getInfoRegister(this.token).subscribe(
      success=>{
        let success_body:any=success;
        this.registered = success_body;
        this._authService.setStoragedToken(this.registered.token);
        this._projectsService.setRegisteredOnStorage(this.registered);
        this.getProjects();
      },
      error=>{
        this._snackBar.open("Error al cargar los proyectos", "Cerrar", {
          duration: 5000,
        });
      }
    );
  }
  
  */
  // lanza el modal con el fomrulario para crear un nuevo proyecto
  addProject(){
    const dialogRef = this.dialog.open(ProjectsNewComponent,{
      height: '455x',
      width: '300px'
    });

    dialogRef.afterClosed().subscribe(result => {
      this.getProjects();
    });
  }

  // lista todos los proyectos
  
  getProjects(){
    this._projectsService.getProjects().subscribe(
      success=>{
        let success_body:any=success;
        this.projects = success_body.body.results;
        this.preloader = true;
      },
      error=>{
     //   this._snackBar.open("Error al cargar los proyectos", "Cerrar", {
      //    duration: 5000,
      //  });
      }
    );
  }
  
  toEditor(project_id){
    console.log(project_id);
    this.storageProject(+project_id);
  }
  storageProject(project_id){
    
    for(let i=0;i<this.projects.length;i++){
      if(project_id == this.projects[i].id){
        this._projectsService.setProjectOnStorage(this.projects[i]);
        break;
      }
    }
    this.getExports(project_id);
  }

  getExports(project_id){
    this.exports = [];
    this._projectsService.getExports(project_id).subscribe(
      success=>{
        let success_body:any = success;
        console.log(success);
        this.exports = success_body.results;
        this.validateStatusExport(project_id);
      },
      error=>{
        console.log(error);
      }
    );
  }

  validateStatusExport(project_id){
    this.sortExports();
    let status = true;
    if(this.exports.length>0){
      if(this.exports[this.exports.length-1].status=="in-progress"){
        status = false;
        Swal.fire({
          type: "warning",
          text: "El proyecto actualmente se encuentra en exportación. Total del progeso: " + this.exports[this.exports.length-1].progress + "%"
        });
      }else if(this.exports[this.exports.length-1].status=="failed"){
        console.log("Una exportación fallo");
      }
    }
    if(status==false){
    //  this._snackBar.open("Un export esta en progreso.", "Cerrar", {
      //  duration: 5000,
      //});
    }else{
      this.toEditorPage(project_id);
    }
  }

  sortExports(){
    this.exports.sort(function(a, b) {
      return a.id - b.id;
    });
  }

  toEditorPage(project_id){
      this._router.navigate(['editor',project_id]);
  }

 removeLocalProject(project_id_param){
  let project_id = parseInt(project_id_param.id_project);
  for(let i=0; i<this.projects.length ; i++){
    if(this.projects[i].id == project_id){
      this.projects.splice(i,1);
    }
  }
}

}
